import { useEffect, useState } from "react";
import useDebouncedValue from "../../hooks/useDebouncedValue";
import { Input } from "semantic-ui-react";

const DebounceInput = ({
  defaultValue,
  value,
  placeholder = "Type here",
  onChange,
  disabled = false,
  type = "string",
  ...props
}) => {
  const [newValue, setNewValue] = useState(defaultValue);

  const debounceFN = useDebouncedValue(newValue, 1000);

  useEffect(() => {
    onChange(newValue);
  }, [debounceFN]);

  useEffect(() => {
    if (value !== newValue) {
      setNewValue(value);
    }
  }, [value]);

  return (
    <Input
      style={{ width: "100%" }}
      value={newValue ?? undefined}
      type={type}
      placeholder={placeholder}
      {...props}
      disabled={disabled}
      onChange={(e) => setNewValue(e?.target?.value ?? null)}
    />
  );
};

export default DebounceInput;
