import { RECEIPT_DEL_MESSAGE, RECEIPT_DEL_PDF_URL_BUILDING, RECEIPT_GENERATE_TOOGLE_LOADING, RECEIPT_SET_LIST,RECEIPT_SET_MESSAGE,RECEIPT_SET_PDF_URL_BUILDING,RECEIPT_TOOGLE_LOADING } from '../../utilities/constants'

const INIT_STATE = {
    loading: false,
    receipts: [],
    message:undefined,
    generatePdfLoading:false,
    recentPdfUrl:undefined
  };
  
  const receiptReducer = (state=INIT_STATE,action) => {
    switch(action.type){
        case RECEIPT_SET_LIST:
            return {
                ...state,
                receipts:action.payload
            }
        case RECEIPT_TOOGLE_LOADING:
            return{
                ...state,
                loading:action.payload
            }
        case RECEIPT_GENERATE_TOOGLE_LOADING:
            return{
                ...state,
                generatePdfLoading:action.payload
            }
        case RECEIPT_SET_PDF_URL_BUILDING:
            return{
                ...state,
                recentPdfUrl:action.payload
            }
        case RECEIPT_DEL_PDF_URL_BUILDING:
            return{
                ...state,
                recentPdfUrl:undefined
            }
        case RECEIPT_SET_MESSAGE:
            return{
                ...state,
                message:action.payload,
            }
        case RECEIPT_DEL_MESSAGE:
            return{
                ...state,
                message:undefined,
            }
        default:
            return state
    }
  }
  
  export default receiptReducer
  