import React, { useState } from "react";
import { Input, Icon, Button } from "semantic-ui-react";
import DebounceInput from "../core/DebouceInput";

const ActivityPanel = ({
  title = "Title",
  onAddClick,
  placeholder,
  onChange,
  hideSearch = false,
  extra,
}) => {
  return (
    <div className="container">
      <h3 style={{ margin: "0px" }}>{title}</h3>
      {!hideSearch && (
        <DebounceInput placeholder={placeholder} onChange={onChange} />
        // <Input
        //   icon
        //   placeholder={placeholder}
        //   onChange={(e) => onChange(e.target.value)}
        // >
        //   <input />
        //   <Icon name="search" />
        // </Input>
      )}
      {extra}
      <Button style={{minWidth:"150px"}} color="orange" onClick={() => onAddClick()}>
        Add new
      </Button>
    </div>
  );
};

export default ActivityPanel;
