import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../../redux/actions/index";
import { useLocation, useHistory } from "react-router-dom";
import { toQueryString } from "../../../utilities/helper";
import {
  Button,
  Confirm,
  Loader,
  Dimmer,
  Form,
  FormField,
} from "semantic-ui-react";
import {
  findValueByProperty,
  generateDropdownArray,
  generateIncArrayAsDropdown,
} from "../../core/helpingFunctions";
import ItemViewer from "../../components/ItemViewer";
import DetailsViewModal from "./DetailsViewModal";
import CreateUpdateReceiptModal from "./CreateUpdateReceiptModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GeneratePdfModalReceipt from "./GeneratePdfModalReceipt";
import {
  flatListBangla,
  monthListBangla,
  monthListEnglish,
} from "../../core/listData";

const ReceiptFragment = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // const query.limit = 40;

  const [values, setValues] = useState({
    showEditModal: false,
    showViewModal: false,
    showDeletePopUp: false,
    selectMultiple: false,
    showGeneratePdfModal: false,
    itemsTobeDeleted: {},
    receiptToBeShown: undefined,
    receiptToBeUpdated: undefined,
    buildingList: [],
    renterList: [],
    renterListTemp: [],
    receiptListTemp: [],
    selectedMonthValue: "",
    selectedYearValue: "",
    selectedBuildingValue: "",
    selectedRenterValue: "",
    idxValueOfReceipt: 0,
  });

  const {
    showEditModal,
    showViewModal,
    showGeneratePdfModal,
    showDeletePopUp,
    selectMultiple,
    itemsTobeDeleted,
    receiptToBeShown,
    receiptToBeUpdated,
    buildingList,
    renterList,
    renterListTemp,
    receiptListTemp,
    selectedBuildingValue,
    selectedMonthValue,
    selectedRenterValue,
    selectedYearValue,
    idxValueOfReceipt,
  } = values;
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    dispatch(actionCreators.getReceipts());
    dispatch(actionCreators.getBuildings());
    dispatch(actionCreators.getRenters());

    // const limit = new URLSearchParams(search).get("limit");
    // const skip = new URLSearchParams(search).get("skip");
    // const idx = new URLSearchParams(search).get("idx");
    // const renter = new URLSearchParams(search).get("renter");

    // setQuery({
    //   limit: query.limit,
    //   skip: pageNumber * limit,
    //   idx,
    //   renter,
    // });
  }, []);

  //async values
  const { loading: loadingBuilding, buildings } = useSelector(
    (state) => state.buildings
  );
  const { loading: loadingRenter, renters } = useSelector(
    (state) => state.renters
  );
  const { loading: loadingReceipt, receipts } = useSelector(
    (state) => state.receipts
  );

  //const values
  const [query, setQuery] = useState({ limit: 20, skip: 0 });
  const search = useLocation().search;

  const onQueryChange = (key, val) => {
    setQuery({
      ...query,
      [key]: val,
    });
  };
  //use effects(value depended)
  useEffect(() => {
    // console.log("Query:",query)
    if (Object.keys(query).length > 0) {
      const queryParams = toQueryString(query);
      history.push(`/receipts${queryParams}`);
      console.log("searching ... ", queryParams);
      setTimeout(() => {
        console.log("search finished for ", queryParams);
        dispatch(actionCreators.getReceipts(query));
      }, 500);
    }
  }, [query]);

  useEffect(() => {
    if (pageNumber > -1) {
      setQuery({
        ...query,
        limit: query.limit,
        skip: pageNumber * query.limit,
      });
    }
  }, [pageNumber]);

  const monthList = generateDropdownArray({
    textArray: monthListBangla,
    startValue: 1,
  });

  const yearList = generateIncArrayAsDropdown({ start: 2020, size: 20 });

  useEffect(() => {
    if (receipts) {
      setValues({
        ...values,
        receiptListTemp: receipts,
      });
    }
  }, [receipts]);

  useEffect(() => {
    if (buildings) {
      let arrayId = [];
      let arrayName = [];
      buildings.forEach((building) => {
        arrayId.push(building._id);
        arrayName.push(building.name);
      });
      setValues({
        ...values,
        buildingList: generateDropdownArray({
          textArray: arrayName,
          useValueAsKey: false,
          key: arrayId,
          useLowerCaseValue: false,
        }),
      });
    }
  }, [buildings]);

  useEffect(() => {
    if (renters) {
      console.log("renter initiating");
      let arrayId = [];
      let arrayName = [];
      renters.forEach((renter) => {
        arrayId.push(renter._id);
        arrayName.push(renter.name);
      });
      let array = generateDropdownArray({
        textArray: arrayName,
        useValueAsKey: false,
        key: arrayId,
        useLowerCaseValue: false,
      });
      setValues({
        ...values,
        renterList: array,
        renterListTemp: array,
      });
    }
  }, [renters]);

  useEffect(() => {
    if (selectedBuildingValue !== "-1" && selectedBuildingValue !== "") {
      console.log("building selected:", selectedBuildingValue);
      let index = findValueByProperty({
        array: buildingList,
        searchingProperty: "text",
        searchFor: selectedBuildingValue,
        needIndex: true,
      });
      //console.log("index",index)
      let tempList = [];
      renters.forEach((renter) => {
        if (renter.building.name === buildingList[index].text) {
          tempList.push({
            key: renter._id,
            text: renter.name,
            value: renter.name,
          });
        }
      });
      console.log("temp ", tempList);
      setValues({
        ...values,
        renterListTemp: tempList,
      });
    } else {
      setValues({
        ...values,
        renterListTemp: renterList,
      });
    }
  }, [selectedBuildingValue]);

  //functions

  const onModalClose = () => {
    dispatch(actionCreators.delPdfUrlForBuildingReceipt());
    dispatch(actionCreators.delRecentMessageReceipt());
    setValues({
      ...values,
      showEditModal: false,
      showViewModal: false,
      showGeneratePdfModal: false,
      receiptToBeUpdated: undefined,
      receiptToBeShown: undefined,
    });
  };

  const createNewReceipt = ({ data }) => {
    console.log("Dispatching post receipt ");
    dispatch(actionCreators.createReceipt(data));
  };

  const updateReceiptData = ({ data }) => {
    console.log("Dispatching updateRenter");
    dispatch(actionCreators.updateReceipt(data));
  };

  const onCreateOrEdit = ({ mode, data }) => {
    if (
      (data.building === "" || data.renter === "") &&
      data.month === "" &&
      data.year === ""
    ) {
      toast("Please fill the required fields");
    } else {
      onModalClose();
      switch (mode) {
        case "create":
          createNewReceipt({ data: data });
          return;
        case "edit":
          updateReceiptData({ data: data });
          return;
        default:
          console.log(mode);
          console.log(data);
          return;
      }
    }
  };

  const onFilter = () => {
    // if(!(selectedBuildingValue==="" && selectedRenterValue==="" && selectedYearValue==="" && selectedMonthValue==="" && !idxValueOfReceipt>0)){

    // }
    setQuery({
      ...query,
      building:
        selectedBuildingValue !== ""
          ? findValueByProperty({
              array: buildingList,
              searchingProperty: "value",
              searchFor: selectedBuildingValue,
              resultProperty: "key",
            })
          : undefined,
      renter:
        selectedRenterValue !== ""
          ? findValueByProperty({
              array: renterList,
              searchingProperty: "value",
              searchFor: selectedRenterValue,
              resultProperty: "key",
            })
          : undefined,
      month:
        selectedMonthValue !== "" ? parseInt(selectedMonthValue) : undefined,
      year: selectedYearValue != "" ? parseInt(selectedYearValue) : undefined,
      idx: idxValueOfReceipt > 0 ? idxValueOfReceipt : undefined,
    });
    // console.log(query);
  };

  const onAddClick = () => {
    console.log("on add clicked");
    setValues({
      ...values,
      showEditModal: true,
    });
  };

  const onItemClick = (index) => {
    if (selectMultiple) {
      let items = { ...itemsTobeDeleted };
      if (itemsTobeDeleted[index]) {
        delete items[index];
      } else {
        items = { ...itemsTobeDeleted, [index]: receiptListTemp[index]._id };
      }
      setValues({
        ...values,
        itemsTobeDeleted: items,
      });
    } else {
      setValues({
        ...values,
        receiptToBeShown: receiptListTemp[index],
        showViewModal: true,
      });
    }
    console.log("item clicked", index);
  };

  const onEditItem = (index) => {
    setValues({
      ...values,
      receiptToBeUpdated: receiptListTemp[index],
      showEditModal: true,
    });
    console.log("edit clicked", index);
  };

  const onDeleteItem = () => {
    console.log("delete confirmed", itemsTobeDeleted);
    if (Object.entries(itemsTobeDeleted).length === 0) {
      toast("No item selected");
    } else {
      let deleteIds = [];
      for (const [key, value] of Object.entries(itemsTobeDeleted)) {
        deleteIds = [...deleteIds, value];
        // console.log(key,value)
      }
      dispatch(actionCreators.deleteReceiptAsList(deleteIds));
      console.log("delete ids", deleteIds);
      setValues({
        ...values,
        showDeletePopUp: false,
        itemsTobeDeleted: {},
        selectMultiple: false,
      });
    }
  };

  const buildPrimaryText = (receipt) => {
    const { building, renter, month, year, total } = receipt;

    return `${building?.name} - ${renter?.name} (${
      flatListBangla[renter?.floor]
    } তলা) - ${total} টাকা - ${monthListBangla[month - 1]}, ${year}`;
  };

  const buildSecondaryText = (receipt) => {
    const { bill_rent, bill_gas, bill_dirt, bill_electricity, total } = receipt;
    return `ভাড়াঃ ${bill_rent || 0} টাকা, গ্যাসঃ ${bill_gas || 0} টাকা, ময়লাঃ ${
      bill_dirt || 0
    } টাকা, বিদ্যুৎঃ ${bill_electricity} টাকা, সর্বমোটঃ ${total} টাকা।`;
  };

  return (
    <div className="parentContainer">
      <>
        <Form>
          <div className=" container">
            <Form.Dropdown
              label="Building"
              loading={loadingBuilding}
              clearable
              options={buildingList}
              placeholder="Choose building"
              selection
              onChange={(e, { value }) => {
                setValues({
                  ...values,
                  selectedBuildingValue: value,
                });
              }}
            />
            <Form.Dropdown
              label="Renter"
              loading={loadingRenter}
              clearable
              options={renterListTemp}
              placeholder="Choose renter"
              selection
              onChange={(e, { value }) => {
                setValues({
                  ...values,
                  selectedRenterValue: value,
                });
              }}
            />

            <Form.Dropdown
              label="Month"
              clearable
              options={monthList}
              placeholder="Choose month"
              selection
              onChange={(e, { value }) =>
                setValues({
                  ...values,
                  selectedMonthValue: value,
                })
              }
            />
            <Form.Dropdown
              label="Year"
              clearable
              options={yearList}
              placeholder="Choose year"
              selection
              onChange={(e, { value }) =>
                setValues({
                  ...values,
                  selectedYearValue: value,
                })
              }
            />

            <FormField>
              <label>Receipt Id</label>
              <input
                type="number"
                placeholder="Receipt id"
                onChange={(e) => {
                  setValues({
                    ...values,
                    idxValueOfReceipt: e.target.value,
                  });
                }}
              />
            </FormField>
            <Form.Button
              style={{ marginTop: "1.5rem" }}
              onClick={() => onFilter()}
            >
              Filter
            </Form.Button>
            <Form.Button
              style={{ marginTop: "10px", minWidth: "150px" }}
              color="orange"
              onClick={onAddClick}
            >
              Add new
            </Form.Button>
          </div>
        </Form>
      </>

      <div style={{ alignSelf: "flex-start" }}>
        {selectMultiple && (
          <Button
            onClick={() => {
              setValues({
                ...values,
                selectMultiple: false,
                itemsTobeDeleted: {},
              });
            }}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={() => {
            if (selectMultiple) {
              setValues({ ...values, showDeletePopUp: true });
            } else {
              setValues({ ...values, selectMultiple: !selectMultiple });
            }
          }}
          color={selectMultiple && "red"}
        >
          {selectMultiple ? "Delete all" : "Delete multiple"}
        </Button>
        {!selectMultiple && (
          <Button
            color="blue"
            onClick={() => {
              setValues({
                ...values,
                showGeneratePdfModal: true,
              });
            }}
          >
            Generate PDF for building
          </Button>
        )}
      </div>
      <div style={{ height: "50px", alignSelf: "flex-end" }}>
        {!selectMultiple && (
          <div>
            <Button
              disabled={pageNumber === 0}
              onClick={() => {
                setPageNumber(pageNumber !== 0 ? pageNumber - 1 : 0);
                setQuery({ ...query, skip: pageNumber * query.limit });
              }}
            >
              Previous
            </Button>
            <Button
              disabled={receipts.length === 0 || receipts.length < query.limit}
              onClick={() => {
                receipts.length !== 0 && setPageNumber(pageNumber + 1);
                setQuery({ ...query, skip: (pageNumber + 1) * query.limit });
              }}
            >
              Next
            </Button>
          </div>
        )}
      </div>

      {loadingReceipt ? (
        <span class="loader"></span>
      ) : receiptListTemp.length !== 0 ? (
        receiptListTemp.map((receipt, index) => (
          <ItemViewer
            index={index + pageNumber * query.limit}
            selectMultiple={selectMultiple}
            isSelected={
              itemsTobeDeleted && itemsTobeDeleted[index] ? true : false
            }
            primaryText={buildPrimaryText(receipt)}
            secondaryText={buildSecondaryText(receipt)}
            onItemClick={onItemClick}
            onEdit={onEditItem}
            onDelete={() => {
              setValues({
                ...values,
                itemsTobeDeleted: {
                  ...itemsTobeDeleted,
                  [index]: receipt._id,
                },
                showDeletePopUp: true,
              });
            }}
          />
        ))
      ) : (
        <p>No receipts to show</p>
      )}

      {showEditModal && (
        <CreateUpdateReceiptModal
          showModal={showEditModal}
          onModalClose={onModalClose}
          onSubmit={onCreateOrEdit}
          data={receiptToBeUpdated}
          buildingList={buildingList}
          renters={renters}
          renterList={renterList}
          monthList={monthList}
          yearList={yearList}
        />
      )}

      {showViewModal && (
        <DetailsViewModal
          showModal={showViewModal}
          receipt={receiptToBeShown}
          buildings={buildings}
          onModalClose={onModalClose}
        />
      )}

      {showGeneratePdfModal && (
        <GeneratePdfModalReceipt
          showModal={showGeneratePdfModal}
          buildingList={buildingList}
          monthList={monthList}
          yearList={yearList}
          onModalClose={onModalClose}
        />
      )}

      <Dimmer active={false}>
        <Loader>Working on it</Loader>
      </Dimmer>

      <ToastContainer />
      <Confirm
        open={showDeletePopUp}
        confirmButton="Yes,delete "
        cancelButton="No,leave it"
        onConfirm={() => onDeleteItem()}
        onCancel={() =>
          setValues({
            ...values,
            itemsTobeDeleted: {},
            showDeletePopUp: false,
            selectMultiple: false,
          })
        }
      />
    </div>
  );
};

export default ReceiptFragment;
