import { LANDLORD_SET_LIST, LANDLORD_TOOGLE_LOADING } from "../../utilities/constants";

const INIT_STATE = {
  loading: false,
  landlords: [],
  message: undefined,
};
const landlordReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LANDLORD_SET_LIST:
      return {
        ...state,
        landlords: action.payload,
      };
      case LANDLORD_TOOGLE_LOADING:
          return{
              ...state,
              loading:action.payload
          }
    default:
      return state;
  }
};

export default landlordReducer;
