import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "semantic-ui-react";
import { baseUrl } from "../../services/api/apiClient";
import {
  getCookie,
  getLocalStorage,
  setCookie,
  setLocalStorage,
} from "../../utilities/session";

const Login = () => {
  const history = useHistory();

  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (getCookie("token") && getLocalStorage("user")) {
      redirectToLoggedInRoute();
    }
  }, []);

  const redirectToLoggedInRoute = () => {
    history.push("/renters");
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setError("");

    if (!mobile || !password) {
      alert("Complete the fields");
      return;
    }

    // console.log(mobile);
    // console.log(password);

    const response = await fetch(baseUrl + "signin", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        mobile,
        password,
      }),
    });

    const data = await response.json();
    if (!data) {
      setError("No data");
      return;
    }

    const { user, token } = data;
    if (token) {
      setCookie("token", token);
    }

    if (user) {
      setLocalStorage("user", user);
    }

    redirectToLoggedInRoute();
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Form>
        <Form.Field>
          <label>Phone Number</label>
          <input
            type="number"
            placeholder="Phone number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <input
            placeholder="Password"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Field>
        <Form.Button onClick={onSubmit}>Log in</Form.Button>
      </Form>

      {error && <p color="red">{error}</p>}
    </div>
  );
};

export default Login;
