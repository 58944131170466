import { call, put, takeLatest, fork, all, take } from "redux-saga/effects";
import {
  deleteReading,
  getReadingsList,
  postReading,
  putReading,
} from "../../services/api/Reading";
import {
  READING_CREATE,
  READING_DELETE,
  READING_GET_LIST,
  READING_UPDATE,
} from "../../utilities/constants";
import * as actionCreators from "../actions";

export function* getReadings({ payload}) {
  yield put(actionCreators.toogleLoadingReading(true));
  try {
    const readings = yield call(getReadingsList, payload);
    yield put(actionCreators.setReadings(readings));
    yield put(actionCreators.toogleLoadingReading(false));
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toogleLoadingReading(false));
  }
  yield put(actionCreators.toogleLoadingReading(false));
}

export function* createReading({ payload }) {
  try {
    console.log("Response collected in saga", payload);
    yield put(actionCreators.toogleLoadingReading(true));
    const res = yield call(postReading, { data: payload });

    if (res.error) {
      yield put(actionCreators.toogleLoadingReading(false));
      console.log(res.error);
    } else {
      console.log("Success", res);
      yield put(actionCreators.getReadings());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateReading({ payload }) {
  try {
    console.log("Response collected in saga(update)", payload);
    yield put(actionCreators.toogleLoadingReading(true));
    const res = yield call(putReading, { data: payload });

    if (res.error) {
      yield put(actionCreators.toogleLoadingReading(false));
      console.log(res.error);
    } else {
      console.log("Success", res);
      yield put(actionCreators.getReadings());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteReadingAsList({ payload }) {
  try {
    console.log("Response collected in saga(delete)", payload);
    yield put(actionCreators.toogleLoadingReading(true));
    let res = yield all(payload.map((id) => call(deleteReading, { data: id })));
    if (res.error) {
      yield put(actionCreators.toogleLoadingReading(false));
      console.log(res.error);
    } else {
      console.log("Success", res);
      yield put(actionCreators.getReadings());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getReadingsWatcher() {
  yield takeLatest(READING_GET_LIST, getReadings);
}

export function* createReadingWatcher() {
  yield takeLatest(READING_CREATE, createReading);
}
export function* updateReadingWatcher() {
  yield takeLatest(READING_UPDATE, updateReading);
}

export function* deleteReadingAsListWatcher() {
  yield takeLatest(READING_DELETE, deleteReadingAsList);
}

export default function* rootSaga() {
  yield all([
    fork(getReadingsWatcher),
    fork(createReadingWatcher),
    fork(updateReadingWatcher),
    fork(deleteReadingAsListWatcher),
  ]);
}
