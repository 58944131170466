import { getData, postData ,putData,deleteData} from "./apiClient";

export const getLandlordsList = async () => {
  const data = await getData({collectionName:"landlords", params: {limit: 1000}})
  return data
  //   console.log(res.data)
};

export const postLandlord = async ({data})=>{
  console.log("Post req called")
  return await postData({collectionName:"landlord",data:data})
}

export const putLandlord = async({data})=>{
  console.log("Put req called")
  const collectionName = "landlord/"+data._id
  // console.log(collectionName)
  return await putData({collectionName:collectionName,data:data})
}

export const  deleteLandlord=async({data})=>{
  console.log("del req called")
  const collectionName = "landlord/"+data
  return await deleteData({collectionName:collectionName})
}