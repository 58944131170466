import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Dropdown, Form, FormField } from "semantic-ui-react";
import * as actionCreators from "../../../redux/actions/index";
import { findValueByProperty } from "../../core/helpingFunctions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../services/api/apiClient";


const GeneratePdfModalReceipt = ({
  onModalClose,
  showModal,
  buildingList = [],
  monthList = [],
  yearList = [],
}) => {
  const dispatch = useDispatch();
  const { generatePdfLoading,recentPdfUrl,message } = useSelector((state) => state.receipts);
  const [reqBody, setReqBody] = useState({
    building: "",
    month: "",
    year: "",
    note:""
  });

  const {building,month,year,note} = reqBody

  const onGeneratePdf=()=>{
      console.log(reqBody)
      if(building==="" || month==="" || year===""){
          toast("Please select all options")
      }else{
          dispatch(actionCreators.delPdfUrlForBuildingReceipt())
          dispatch(actionCreators.generateReceiptForBuilding(reqBody))
      }
      
  }

  return (
      <div>
    <Modal open={showModal} onClose={() => onModalClose()}>
      <Modal.Header>
        <h2>Generate PDF for building</h2>
      </Modal.Header>
      <Modal.Content scrolling>
        <Dropdown
          selection
          placeholder="Choose building"
          options={buildingList}
          onChange={(e,{ value }) => {
            setReqBody({
              ...reqBody,
              building: findValueByProperty({
                array: buildingList,
                searchFor: value,
                searchingProperty: "value",
                resultProperty: "key",
                initValue: "",
                enableLowerCaseSearch:false
              }),
            });
          }}
        />

        <Dropdown
          selection
          placeholder="Choose month"
          options={monthList}
          onChange={(e,{ value }) => {
            setReqBody({
              ...reqBody,
              month: findValueByProperty({
                array: monthList,
                searchFor: value,
                searchingProperty: "value",
                resultProperty: "value",
                initValue: "",
                enableLowerCaseSearch:false
              }),
            });
          }}
        />

        <Dropdown
          selection
          placeholder="Choose year"
          options={yearList}
          onChange={(e,{ value }) => {
            setReqBody({
              ...reqBody,
              year: value!==""?parseInt(value):"",
            });
          }}
        />
        <br/><br/>
        <Form>
        <FormField>
            <label>Note</label>
            <input
             placeholder="Write your note here"
             onChange={(e)=>{
                 setReqBody({
                     ...reqBody,
                     note: e.target.value
                 })
             }}
            />
        </FormField>
        </Form>
        <div style={{ height: "50vh" }}>
            <br/>
            {recentPdfUrl && <a href={baseUrl+recentPdfUrl} target="_blank">DOWNLOAD PDF</a>}
            {message && <p>{message}</p>}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={generatePdfLoading} color="brown" onClick={()=>onGeneratePdf()}>{generatePdfLoading?"Generating":"Generate Pdf"}</Button>
        <Button color="red" onClick={() => onModalClose()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
    <ToastContainer />
    </div>
  );
};

export default GeneratePdfModalReceipt;
