import { useEffect, useState } from "react";
import { Modal, Button, Form } from "semantic-ui-react";
import {
  findValueByProperty,
  generateDropdownArray,
  generateIncArrayAsDropdown,
} from "../../core/helpingFunctions";
import { flatListBangla } from "../../core/listData";

const CreateUpdateModal = ({
  data,
  showModal,
  onModalClose,
  onSubmit,
  buildings = [],
}) => {
  const [values, setValues] = useState({
    showModal: showModal,
    buildingInitiated: false,
    selectedBuildingValue: "-1",
    buildingInitiated: false,
    buildingList: [],
    flatList: [],
    floorList: [],
    renterData: {
      building: "",
      name: "",
      is_store: false,
      is_active: false,
      floor: 0,
      flat: 0,
      start_at: null,
      end_at: null,
      nid: null,
      mobile: null,
      previous_due: 0,
      no_extra_bill: false,
      bill_rent: 0,
      bill_dirt: 0,
      bill_gas: 0,
    },
  });

  const {
    renterData,
    buildingInitiated,
    selectedBuildingValue,
    flatList,
    floorList,
    buildingList,
  } = values;

  const createBuildingData = () => {
    let buildingNames = [];
    buildings.forEach((building) => {
      buildingNames.push(building.name);
    });
    setValues({
      ...values,
      buildingList: generateDropdownArray({
        textArray: buildingNames,
        useLowerCaseValue: false,
      }),
      buildingInitiated: true,
    });
  };

  !buildingInitiated && createBuildingData();

  useEffect(() => {
    if (data) {
      let index = findValueByProperty({
        array: buildings,
        searchingProperty: "_id",
        searchFor: data.building._id,
        needIndex: true,
      });
      setValues({
        ...values,
        renterData: data,
        selectedBuildingValue: data.building.name,
        flatList: generateDropdownArray({
          textArray: buildings[index].flat_info,
          useLowerCaseValue: false,
        }),
        floorList: generateIncArrayAsDropdown({
          start: 0,
          size: buildings[index].floor_count,
        }),
      });
    }
    // console.log("use effect called")
  }, [data]);

  const setNewRenterData = ({ property, data }) => {
    setValues({
      ...values,
      renterData: {
        ...renterData,
        [property]: data,
      },
    });
    // console.log(newBuildingData);
  };

  useEffect(() => {
    if (selectedBuildingValue !== "-1") {
      let index = findValueByProperty({
        array: buildings,
        searchingProperty: "name",
        searchFor: selectedBuildingValue,
        needIndex: true,
      });
      index !== "-1" &&
        setValues({
          ...values,
          renterData: {
            ...renterData,
            building: buildings[index]._id,
          },
          flatList: generateDropdownArray({
            textArray: buildings[index].flat_info,
            useLowerCaseValue: false,
          }),
          floorList: generateDropdownArray({
            textArray: flatListBangla.slice(0, buildings[index].floor_count),
            startValue: 0,
            useLowerCaseValue: false,
            useValueAsKey: true,
          }),
        });
    }
  }, [selectedBuildingValue]);

  return (
    <Modal open={showModal} onClose={() => onModalClose()}>
      <Modal.Header>
        <h3>{data ? "Update Renter data" : "Add new Renter"}</h3>
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Form>
            <Form.Group widths="two">
              <Form.Field required>
                <label>Renter name</label>
                <input
                  placeholder="Renter name ...."
                  defaultValue={data ? data.name : ""}
                  onChange={(e) =>
                    setNewRenterData({
                      property: "name",
                      data: e.target.value,
                    })
                  }
                />
              </Form.Field>
              <Form.Dropdown
                label="Building"
                placeholder="Choose building"
                selection
                required
                options={buildingList}
                value={selectedBuildingValue}
                onChange={(e) => {
                  setValues({
                    ...values,
                    selectedBuildingValue: e.target.innerText,
                  });
                }}
              />
              <Form.Field>
                <label>Due</label>
                <input
                  placeholder="Due ..."
                  type="number"
                  defaultValue={data ? data.previous_due : ""}
                  onChange={(e) =>
                    setNewRenterData({
                      property: "previous_due",
                      data: e.target.value,
                    })
                  }
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="three">
              <Form.Field>
                <label>Nid no</label>
                <input
                  placeholder="nid no"
                  defaultValue={data ? data.nid : ""}
                  onChange={(e) =>
                    setNewRenterData({
                      property: "nid",
                      data: e.target.value,
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Start at</label>
                <input
                  placeholder="0"
                  type="date"
                  defaultValue={
                    data && data.start_at && data.start_at.substring(0, 10)
                  }
                  onChange={(e) =>
                    setNewRenterData({
                      property: "start_at",
                      data: e.target.value,
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>End at</label>
                <input
                  placeholder="0"
                  type="date"
                  defaultValue={
                    data && data.end_at && data.end_at.substring(0, 10)
                  }
                  onChange={(e) =>
                    setNewRenterData({
                      property: "end_at",
                      data: e.target.value,
                    })
                  }
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="three">
              <Form.Dropdown
                label="Floor"
                required
                placeholder={
                  floorList.length === 0
                    ? "Select building First"
                    : "Choose floor"
                }
                selection
                options={floorList}
                defaultValue={data && flatListBangla[data.floor]}
                onChange={(e, { value }) => {
                  setNewRenterData({
                    property: "floor",
                    data: flatListBangla.indexOf(value),
                  });
                }}
              ></Form.Dropdown>

              <Form.Dropdown
                label="Flat"
                required
                placeholder={
                  flatList.length === 0
                    ? "Select building First"
                    : "Choose flat"
                }
                selection
                options={flatList}
                value={(flatList.length != 0 && renterData.flat < flatList.length) ? flatList[renterData.flat].text : ""}
                onChange={(e, { value }) => {
                  setNewRenterData({
                    property: "flat",
                    data: findValueByProperty({
                      array: flatList,
                      searchingProperty: "text",
                      searchFor: value,
                      needIndex: true,
                    }),
                  });
                }}
              ></Form.Dropdown>
              <Form.Field>
                <label>Mobile no</label>
                <input
                  placeholder="Mobile no ...."
                  type="number"
                  defaultValue={data ? data.mobile : ""}
                  onChange={(e) =>
                    setNewRenterData({
                      property: "mobile",
                      data: e.target.value,
                    })
                  }
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="three">
              <Form.Field>
                <label>Rent</label>
                <input
                  placeholder="0"
                  type="number"
                  defaultValue={data ? data.bill_rent : ""}
                  onChange={(e) =>
                    setNewRenterData({
                      property: "bill_rent",
                      data: e.target.value,
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Bill(dirt)</label>
                <input
                  placeholder="0"
                  type="number"
                  defaultValue={data ? data.bill_dirt : ""}
                  onChange={(e) =>
                    setNewRenterData({
                      property: "bill_dirt",
                      data: e.target.value,
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Bill(gas)</label>
                <input
                  placeholder="0"
                  type="number"
                  defaultValue={data ? data.bill_gas : ""}
                  onChange={(e) =>
                    setNewRenterData({
                      property: "bill_gas",
                      data: e.target.value,
                    })
                  }
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field
                label="Active"
                control="input"
                checked={renterData.is_active}
                type="checkbox"
                onChange={(e) =>
                  setNewRenterData({
                    property: "is_active",
                    data: e.target.checked,
                  })
                }
              ></Form.Field>
              <Form.Field
                label="Store"
                control="input"
                checked={renterData.is_store}
                type="checkbox"
                onChange={(e) =>
                  setNewRenterData({
                    property: "is_store",
                    data: e.target.checked,
                  })
                }
              ></Form.Field>
              <Form.Field
                label="No Extra Bill"
                control="input"
                checked={renterData.no_extra_bill}
                type="checkbox"
                onChange={(e) =>
                  setNewRenterData({
                    property: "no_extra_bill",
                    data: e.target.checked,
                  })
                }
              ></Form.Field>
            </Form.Group>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="brown"
          onClick={() =>
            onSubmit({ mode: data ? "edit" : "create", data: renterData })
          }
        >
          {data ? "Update renter" : "Create Renter"}
        </Button>
        <Button color="red" onClick={() => onModalClose()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CreateUpdateModal;
