import {
  deleteReceipt,
  getGenerateReceiptForRenter,
  getReceiptList,
  postGenerateReceiptForBuildingOrRenter,
  postReceipt,
  putReceipt,
} from "../../services/api/Receipt";
import {
  RECEIPT_GET_LIST,
  RECEIPT_UPDATE,
  RECEIPT_DELETE,
  RECEIPT_CREATE,
  RECEIPT_GENERATE_RENTER,
  RECEIPT_POST_GENERATE_BUILDING,
} from "../../utilities/constants";
import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import * as actionCreators from "../actions";

export function* getReceipts({ payload }) {
  yield put(actionCreators.toogleLoadingReceipt(true));
  try {
    const receipts = yield call(getReceiptList, { params: payload });
    yield put(actionCreators.setReceipts(receipts));
    yield put(actionCreators.toogleLoadingReceipt(false));
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toogleLoadingReceipt(false));
  }
  yield put(actionCreators.toogleLoadingReceipt(false));
}

export function* generateReceiptForRenter({ payload }) {
  yield put(actionCreators.toogleGenerateReceiptLoading(true));
  try {
    const res = yield call(getGenerateReceiptForRenter, { data: payload });
    if (res.error) {
      console.log(res.error);
    }
    if (res.url) {
      window.open(res.url);
    }
  } catch (error) {
    console.log(error);
  }
  yield put(actionCreators.toogleGenerateReceiptLoading(false));
}

export function* generateReceiptForBuilding({ payload }) {
  yield put(actionCreators.toogleGenerateReceiptLoading(true));
  try {
    const res = yield call(postGenerateReceiptForBuildingOrRenter, {
      data: payload,
    });
    if (res.error) {
      console.log(res.error);
    } else {
      if (!res.data.download_url) {
        yield put(
          actionCreators.setRecentMessageReceipt(
            "No receipt found with this data"
          )
        );
      } else {
        yield put(actionCreators.delRecentMessageReceipt());
        console.log("Success", res);
        yield put(
          actionCreators.setPdfUrlForBuildingReceipt(res.data.download_url)
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
  yield put(actionCreators.toogleGenerateReceiptLoading(false));
}

export function* createReceipt({ payload }) {
  try {
    console.log("Response collected in saga", payload);
    yield put(actionCreators.toogleLoadingReceipt(true));
    const res = yield call(postReceipt, { data: payload });
    if (res.error) {
      yield put(actionCreators.toogleLoadingReceipt(false));
      console.log(res.error);
    } else {
      console.log("Success", res);
      yield put(actionCreators.getReceipts());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateReceipt({ payload }) {
  try {
    console.log("Response collected in saga(update)", payload);
    yield put(actionCreators.toogleLoadingReceipt(true));
    const res = yield call(putReceipt, { data: payload });
    if (res.error) {
      yield put(actionCreators.toogleLoadingReceipt(false));
      console.log(res.error);
    } else {
      console.log("Success", res);
      yield put(actionCreators.getReceipts());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteReceiptAsList({ payload }) {
  try {
    console.log("Response collected in saga(delete)", payload);
    yield put(actionCreators.toogleLoadingReceipt(true));
    let res = yield all(payload.map((id) => call(deleteReceipt, { data: id })));
    if (res.error) {
      yield put(actionCreators.toogleLoadingReceipt(false));
      console.log(res.error);
    } else {
      console.log("Success", res);
      yield put(actionCreators.getReceipts());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getReceiptsWatcher() {
  yield takeLatest(RECEIPT_GET_LIST, getReceipts);
}
export function* generateReceiptForRenterWatcher() {
  yield takeLatest(RECEIPT_GENERATE_RENTER, generateReceiptForRenter);
}
export function* generateReceiptForBuildingWatcher() {
  yield takeLatest(RECEIPT_POST_GENERATE_BUILDING, generateReceiptForBuilding);
}
export function* postReceiptWatcher() {
  yield takeLatest(RECEIPT_CREATE, createReceipt);
}
export function* putReceiptWatcher() {
  yield takeLatest(RECEIPT_UPDATE, updateReceipt);
}
export function* deleteReceiptAsListWatcher() {
  yield takeLatest(RECEIPT_DELETE, deleteReceiptAsList);
}

export default function* rootSaga() {
  yield all([
    fork(getReceiptsWatcher),
    fork(putReceiptWatcher),
    fork(postReceiptWatcher),
    fork(deleteReceiptAsListWatcher),
    fork(generateReceiptForRenterWatcher),
    fork(generateReceiptForBuildingWatcher),
  ]);
}
