import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import { deleteRenter, getRentersList, postRenter, putRenter } from "../../services/api/Renter";
import * as actionCreators from "../actions";
import { RENTER_CREATE, RENTER_DELETE, RENTER_GET_LIST, RENTER_UPDATE } from "../../utilities/constants";

export function* getRenders({ payload}) {
  yield put(actionCreators.toogleLoadingRenter(true))
  try {
    const renters = yield call(getRentersList, payload);
    yield put(actionCreators.setRenters(renters));
    yield put(actionCreators.toogleLoadingRenter(false));
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toogleLoadingRenter(false));
  }
  yield put(actionCreators.toogleLoadingRenter(false))
}

export function* createRenter({ payload }) {
  try {
    console.log("Response collected in saga", payload);
    yield put(actionCreators.toogleLoadingRenter(true));
    const res = yield call(postRenter, { data: payload });
    if (res.error) {
      yield put(actionCreators.toogleLoadingRenter(false));
      console.log(res.error);
    } else {
      console.log("Success", res);
      yield put(actionCreators.getRenters());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateRenter({ payload }) {
    try {
      console.log("Response collected in saga(update)", payload);
      yield put(actionCreators.toogleLoadingRenter(true));
      const res = yield call(putRenter, { data: payload });
      if (res.error) {
        yield put(actionCreators.toogleLoadingRenter(false));
        console.log(res.error);
      } else {
        console.log("Success", res);
        yield put(actionCreators.getRenters());
      }
    } catch (error) {
      console.log(error);
    }
  }

  export function* deleteRentersAsList({payload}){
    try{
      console.log("Response collected in saga(delete)", payload)
        yield put(actionCreators.toogleLoadingRenter(true))
        let res = yield all(payload.map((id)=>call(deleteRenter,{data:id})))
        if(res.error){
          yield put(actionCreators.toogleLoadingRenter(false))
            console.log(res.error)
        }else{
            console.log("Success", res)
            yield put(actionCreators.getRenters())
        }
    }catch(error){
        console.log(error)
    }
  }


export function* getRendersWatcher() {
  yield takeLatest(RENTER_GET_LIST, getRenders);
}

export function* createRenterWatcher(){
    yield takeLatest(RENTER_CREATE,createRenter)
}

export function* updateRenterWatcher(){
    yield takeLatest(RENTER_UPDATE,updateRenter)
}
export function* deleteRenterAsListWatcher(){
  yield takeLatest(RENTER_DELETE,deleteRentersAsList)
}

export default function* rootSaga() {
  yield all([
      fork(getRendersWatcher),
      fork(createRenterWatcher),
      fork(updateRenterWatcher),
      fork(deleteRenterAsListWatcher),
]);
}
