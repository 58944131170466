import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "semantic-ui-react";
import * as actionCreators from "../../../redux/actions/index";
import { flatListBangla } from "../../core/listData";

const DetailsViewModal = ({
  showModal,
  receipt = undefined,
  onModalClose,
  buildings = [],
}) => {
  const dispatch = useDispatch();
  const {generatePdfLoading} = useSelector((state)=>state.receipts)

  var flatInfo = [];
  buildings.forEach((building) => {
    if (building._id === receipt.building._id) {
      flatInfo = building.flat_info;
      return;
    }
  });
  const onGeneratePdf = () => {
    dispatch(actionCreators.generateReceiptForRenter(receipt));
  };

  return (
    <Modal open={showModal} onClose={() => onModalClose()}>
      <Modal.Header>
        <h2>Receipt deatils</h2>
      </Modal.Header>
      <Modal.Content scrolling>
        {receipt && (
          <div>
            <p>{"Building name :  " + receipt.building.name}</p>
            <p>{"Renter name :  " + receipt.renter.name}</p>
            <p>{"Floor :  " + flatListBangla[receipt.renter.floor]}</p>
            <p>{"Flat:  " + flatInfo[receipt.renter.flat]}</p>
            <p>{"Month :  " + receipt.month}</p>
            <p>{"Year :  " + receipt.year}</p>
            <p>{"Bill(rent) :  " + receipt.bill_rent}</p>
            <p>{"Bill(electricity) :  " + receipt.bill_electricity}</p>
            <p>{"Bill(gas) :  " + receipt.bill_gas}</p>
            <p>{"Bill(dirt) :  " + receipt.bill_dirt}</p>
            <p>{"Total bill :  " + receipt.total}</p>
            <p>{"Paid :  " + receipt.paid}</p>
            <p>
              {"Electrcity(unit current) :  " +
                receipt.electricity.unit_current}
            </p>
            <p>
              {"Electrcity(unit previous) :  " +
                receipt.electricity.unit_previous}
            </p>
            <p>{"Electrcity(unit used) :  " + receipt.electricity.unit_used}</p>
            <p>
              {"Electrcity(unit price) :  " + receipt.electricity.unit_price}
            </p>
            <p>
              {"Electrcity(date current) :  " +
                receipt.electricity.date_current.substring(0, 10)}
            </p>
            <p>
              {"Electrcity(date previous) :  " +
                receipt.electricity.date_previous.substring(0, 10)}
            </p>

            <p>{"Created at :  " + receipt.createdAt.substring(0, 10)}</p>
            <p>{"Updated at :  " + receipt.updatedAt.substring(0, 10)}</p>
            <p>{"Updated by : " + receipt.updated_by.name}</p>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button disabled={generatePdfLoading} color="brown" onClick={()=>onGeneratePdf()}>{generatePdfLoading?"Generating":"Generate pdf"}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DetailsViewModal;
