import { useEffect, useState } from "react";
import { Modal, Button, Form, FormField, Dropdown } from "semantic-ui-react";
import { findValueByProperty } from "../../core/helpingFunctions";

const CreateUpdateReceiptModal = ({
  data = undefined,
  showModal,
  onModalClose,
  onSubmit,
  buildingList = [],
  renters = [],
  monthList = [],
  yearList = [],
  renterList = [],
}) => {
  const [values, setValues] = useState({
    renterListTemp: renterList,
    selectedBuildingValue: "",
    selectedRenterValue: "",
    selectedMonthValue: "",
    selectedYearValue: "",
    receipt: data
      ? data
      : {
          building: undefined,
          renter: undefined,
          month: "",
          year: "",
        },
  });

  const {
    receipt,
    renterListTemp,
    selectedRenterValue,
    selectedBuildingValue,
    selectedYearValue,
    selectedMonthValue,
  } = values;

  useEffect(() => {
    if (selectedBuildingValue !== "") {
      console.log("building selected:", selectedBuildingValue);
      let index = findValueByProperty({
        array: buildingList,
        searchingProperty: "text",
        searchFor: selectedBuildingValue,
        needIndex: true,
      });
      //console.log("index",index)
      let tempList = [];
      renters.forEach((renter) => {
        if (renter.building.name === buildingList[index].text) {
          tempList.push({
            key: renter._id,
            text: renter.name,
            value: renter.name,
          });
        }
      });
      setValues({
        ...values,
        renterListTemp: tempList,
        receipt: {
          ...receipt,
          building: buildingList[index].key,
        },
      });
    } else {
      setValues({
        ...values,
        renterListTemp: renterList,
        receipt: {
          ...receipt,
          building: "",
        },
      });
    }
  }, [selectedBuildingValue]);

  useEffect(() => {
    console.log("receipt", receipt);
  }, [receipt]);

  return (
    <Modal open={showModal} onClose={() => onModalClose()}>
      <Modal.Header>
        <h3>{data ? "Update Receipt data" : "Add new Receipt"}</h3>
      </Modal.Header>
      <Modal.Content scrolling>
        {data ? (
          <div>
            <p>{"Building : " + data.building.name}</p>
            <p>{"Renter : " + data.renter.name}</p>
            <p>{"Total bill : " + data.total}</p>
            <Form>
              <FormField>
                <label>Paid amount</label>
                <input
                  placeholder="Pain amount ...."
                  type="number"
                  defaultValue={data ? data.paid : 0}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      receipt: {
                        ...receipt,
                        paid: parseInt(e.target.value),
                      },
                    })
                  }
                />
              </FormField>
            </Form>
          </div>
        ) : (
          <div>
            <Dropdown
              clearable
              options={buildingList}
              placeholder="Choose building"
              selection
              onChange={(e, { value }) =>
                setValues({
                  ...values,
                  selectedBuildingValue: value,
                })
              }
            />
            <Dropdown
              clearable
              options={renterListTemp}
              placeholder="Choose Renter"
              selection
              onChange={(e, { value }) => {
                let index = findValueByProperty({
                  array: renterListTemp,
                  searchFor: value,
                  searchingProperty: "text",
                  needIndex: true,
                });
                setValues({
                  ...values,
                  selectedRenterValue: value,
                  receipt: {
                    ...receipt,
                    renter: index !== "-1" ? renterListTemp[index].key : "",
                  },
                });
              }}
            />
            <Dropdown
              clearable
              options={monthList}
              placeholder="Choose month"
              selection
              onChange={(e, { value }) => {

                setValues({
                  ...values,
                  receipt: {
                    ...receipt,
                    month: value>0 ? value : "",
                  },
                });
              }}
            />

            <Dropdown
              clearable
              options={yearList}
              placeholder="Choose year"
              selection
              onChange={(e, { value }) => {
                let index = findValueByProperty({
                  array: yearList,
                  searchingProperty: "text",
                  searchFor: value,
                  needIndex: true,
                });
                setValues({
                  ...values,
                  receipt: {
                    ...receipt,
                    year: index !== "-1" ? yearList[index].key : "",
                  },
                });
              }}
            />
          </div>
        )}
        {!data && <div style={{ height: "200px" }}></div>}
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="brown"
          onClick={() =>
            onSubmit({ mode: data ? "edit" : "create", data: receipt })
          }
        >
          {data ? "Update receipt" : "Create Receipt"}
        </Button>
        <Button color="red" onClick={() => onModalClose()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CreateUpdateReceiptModal;
