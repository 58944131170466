import {
  BUILDING_CREATE,
  BUILDING_DELETE,
  BUILDING_GET_LIST,
  BUILDING_SET_LIST,
  BUILDING_TOOGLE_LOADING,
  BUILDING_UPDATE,
  LANDLORD_CREATE,
  LANDLORD_DELETE,
  LANDLORD_GET_LIST,
  LANDLORD_SET_LIST,
  LANDLORD_TOOGLE_LOADING,
  LANDLORD_UPDATE,
  READING_CREATE,
  READING_DELETE,
  READING_GET_LIST,
  READING_SET_LIST,
  READING_TOOGLE_LOADING,
  READING_UPDATE,
  RENTERD_TOOGLE_LOADING,
  RENTER_CREATE,
  RENTER_DELETE,
  RENTER_GET_LIST,
  RENTER_SET_LIST,
  RENTER_UPDATE,
  RECEIPT_CREATE,
  RECEIPT_DELETE,
  RECEIPT_UPDATE,
  RECEIPT_GET_LIST,
  RECEIPT_SET_LIST,
  RECEIPT_TOOGLE_LOADING,
  RECEIPT_GENERATE_RENTER,
  RECEIPT_POST_GENERATE_BUILDING,
  RECEIPT_GENERATE_TOOGLE_LOADING,
  RECEIPT_SET_PDF_URL_BUILDING,
  RECEIPT_SET_MESSAGE,
  RECEIPT_DEL_MESSAGE,
  DYNAMIC_RECEIPT_GET_LIST,
  DYNAMIC_RECEIPT_SET_LIST,
  DYNAMIC_RECEIPT_SET_MESSAGE,
  DYNAMIC_RECEIPT_DEL_MESSAGE,
  DYNAMIC_RECEIPT_GENERATE_TOOGLE_LOADING,
  DYNAMIC_RECEIPT_TOOGLE_LOADING,
  DYNAMIC_RECEIPT_CREATE,
  DYNAMIC_RECEIPT_DELETE,
  APP_STATE_TOOGLE_LOGOUT_CONFIRMATION
} from "../../utilities/constants";

// APP STATES
export const toogleLogOutConfirmation=(toogle)=>({
  type: APP_STATE_TOOGLE_LOGOUT_CONFIRMATION,
  payload: toogle
})


//Buildings
export const getBuildings = (query) => ({
  type: BUILDING_GET_LIST,
  payload:query
});

export const setBuildings = (data) => ({
  type: BUILDING_SET_LIST,
  payload: data,
});

export const createBuilding = (data)=>({
  type:BUILDING_CREATE,
  payload:data
})

export const toogleLoadingBuilding=(toogle)=>({
  type:BUILDING_TOOGLE_LOADING,
  payload: toogle
})

export const updateBuilding=(data)=>({
  type:BUILDING_UPDATE,
  payload: data
})

export const deleteBuildingAsList=(data)=>({
  type:BUILDING_DELETE,
  payload:data
})



//Renters
export const getRenters = (query) => ({
  type: RENTER_GET_LIST,
  payload:query
});

export const setRenters = (renters) => ({
  type: RENTER_SET_LIST,
  payload: renters,
});

export const createRenter = (data)=>({
  type:RENTER_CREATE,
  payload:data
})

export const toogleLoadingRenter=(toogle)=>({
  type:RENTERD_TOOGLE_LOADING,
  payload: toogle
})

export const updateRenter=(data)=>({
  type:RENTER_UPDATE,
  payload: data
})

export const deleteRenterAsList=(data)=>({
  type:RENTER_DELETE,
  payload:data
})



//Landlords
export const getLandlords = (query) => ({
  type: LANDLORD_GET_LIST,
  payload:query
});

export const setLandlords = (data) => ({
  type: LANDLORD_SET_LIST,
  payload: data,
});

export const createLandlord = (data)=>({
  type:LANDLORD_CREATE,
  payload:data
})

export const toogleLoadingLandlord=(toogle)=>({
  type:LANDLORD_TOOGLE_LOADING,
  payload: toogle
})

export const updateLandlord=(data)=>({
  type:LANDLORD_UPDATE,
  payload: data
})

export const deleteLandlordAsList=(data)=>({
  type:LANDLORD_DELETE,
  payload:data
})



//Readings
export const getReadings = (query) => ({
  type: READING_GET_LIST,
  payload:query
});

export const setReadings = (readings) => ({
  type: READING_SET_LIST,
  payload: readings,
});

export const createReading = (data)=>({
  type:READING_CREATE,
  payload:data
})

export const toogleLoadingReading=(toogle)=>({
  type:READING_TOOGLE_LOADING,
  payload: toogle
})

export const updateReading=(data)=>({
  type:READING_UPDATE,
  payload: data
})

export const deleteReadingAsList=(data)=>({
  type:READING_DELETE,
  payload:data
})


//Receipts
export const getReceipts = (params="") => ({
  type: RECEIPT_GET_LIST,
  payload:params
});

export const setReceipts = (receipts) => ({
  type: RECEIPT_SET_LIST,
  payload: receipts,
});

export const createReceipt = (data)=>({
  type:RECEIPT_CREATE,
  payload:data
})

export const toogleLoadingReceipt=(toogle)=>({
  type:RECEIPT_TOOGLE_LOADING,
  payload: toogle
})

export const updateReceipt=(data)=>({
  type:RECEIPT_UPDATE,
  payload: data
})

export const deleteReceiptAsList=(data)=>({
  type:RECEIPT_DELETE,
  payload:data
})

export const toogleGenerateReceiptLoading=(toogle)=>({
  type: RECEIPT_GENERATE_TOOGLE_LOADING,
  payload: toogle
})

export const generateReceiptForRenter=(data)=>({
  type: RECEIPT_GENERATE_RENTER,
  payload:data
})

export const generateReceiptForBuilding=(data)=>({
  type: RECEIPT_POST_GENERATE_BUILDING,
  payload:data
})

export const setPdfUrlForBuildingReceipt=(url)=>({
  type: RECEIPT_SET_PDF_URL_BUILDING,
  payload:url
})

export const delPdfUrlForBuildingReceipt=()=>({
  type: RECEIPT_SET_PDF_URL_BUILDING,
})

export const setRecentMessageReceipt=(message)=>({
  type: RECEIPT_SET_MESSAGE,
  payload:message
})

export const delRecentMessageReceipt=()=>({
  type:RECEIPT_DEL_MESSAGE,
})


//dynamic receipts

export const getDynamicReceipts=(params="")=>({
  type: DYNAMIC_RECEIPT_GET_LIST,
  payload:params
})

export const setDynamicReceipts=(dynamicReceipts)=>({
  type:DYNAMIC_RECEIPT_SET_LIST,
  payload:dynamicReceipts
})

export const createDynamicReceipt = (data)=>({
  type:DYNAMIC_RECEIPT_CREATE,
  payload:data
})

export const deleteDynamicReceiptAsList=(data)=>({
  type:DYNAMIC_RECEIPT_DELETE,
  payload:data
})


export const toogleLoadingDynamicReceipt=(toogle)=>({
  type:DYNAMIC_RECEIPT_TOOGLE_LOADING,
  payload: toogle
})

export const toogleGenerateDynamicReceiptLoading=(toogle)=>({
  type: DYNAMIC_RECEIPT_GENERATE_TOOGLE_LOADING,
  payload: toogle
})

export const setDynamicRecentMessageReceipt=(message)=>({
  type: DYNAMIC_RECEIPT_SET_MESSAGE,
  payload:message
})

export const delDynamicRecentMessageReceipt=()=>({
  type:DYNAMIC_RECEIPT_DEL_MESSAGE,
})