import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Dropdown,Form,FormField } from "semantic-ui-react";
import { findValueByProperty } from "../../core/helpingFunctions";
import * as actionCreators from "../../../redux/actions/index";


const CreateDynamicReceiptModal = ({
  showModal,
  onModalClose,
  onSubmit,
  buildingList = [],
  renters = [],
  monthList = [],
  yearList = [],
  renterList = [],
}) => {

  const [values, setValues] = useState({
    renterListTemp: renterList,
    selectedBuildingValue: "",
    selectedRenterValue: "",
    selectedMonthValue: "",
    selectedYearValue: "",
    dynamicReceipt: {
      building: undefined,
      renter: undefined,
      month: "",
      year: "",
      note:""
    },
  });

  const {
    dynamicReceipt,
    renterListTemp,
    selectedRenterValue,
    selectedBuildingValue,
    selectedYearValue,
    selectedMonthValue,
  } = values;

  const { generatePdfLoading,message} = useSelector(
    (state) => state.dynamicReceipts
  );

  useEffect(()=>{
  if(message && message==="Ok"){
    setInterval(onModalClose,2000)
  }

  },[message])

  useEffect(() => {
    if (selectedBuildingValue !== "") {
      console.log("building selected:", selectedBuildingValue);
      let index = findValueByProperty({
        array: buildingList,
        searchingProperty: "text",
        searchFor: selectedBuildingValue,
        needIndex: true,
      });
      
      let tempList = [];
      renters.forEach((renter) => {
        if (renter.building.name === buildingList[index].text) {
          tempList.push({
            key: renter._id,
            text: renter.name,
            value: renter.name,
          });
        }
      });
      setValues({
        ...values,
        renterListTemp: tempList,
        dynamicReceipt: {
          ...dynamicReceipt,
          building: buildingList[index].key,
        },
      });
    } else {
      setValues({
        ...values,
        renterListTemp: renterList,
        dynamicReceipt: {
          ...dynamicReceipt,
          building: "",
        },
      });
    }
  }, [selectedBuildingValue]);

  useEffect(() => {
    console.log("receipt", dynamicReceipt);
  }, [dynamicReceipt]);

  return (
    <Modal open={showModal} onClose={() => onModalClose()}>
      <Modal.Header>
        <h3>Add new dynamic Receipt</h3>
      </Modal.Header>
      <Modal.Content scrolling>
        <div>
          <Dropdown
            clearable
            options={buildingList}
            placeholder="Choose building"
            selection
            onChange={(e, { value }) =>
              setValues({
                ...values,
                selectedBuildingValue: value,
              })
            }
          />
          <Dropdown
            clearable
            options={renterListTemp}
            placeholder="Choose Renter"
            selection
            onChange={(e, { value }) => {
              let index = findValueByProperty({
                array: renterListTemp,
                searchFor: value,
                searchingProperty: "text",
                needIndex: true,
              });
              setValues({
                ...values,
                selectedRenterValue: value,
                dynamicReceipt: {
                  ...dynamicReceipt,
                  renter: index !== "-1" ? renterListTemp[index].key : "",
                },
              });
            }}
          />
          <Dropdown
            clearable
            options={monthList}
            placeholder="Choose month"
            selection
            onChange={(e, { value }) => {
              setValues({
                ...values,
                dynamicReceipt: {
                  ...dynamicReceipt,
                  month: value > 0 ? value : "",
                },
              });
            }}
          />

          <Dropdown
            clearable
            options={yearList}
            placeholder="Choose year"
            selection
            onChange={(e, { value }) => {
              let index = findValueByProperty({
                array: yearList,
                searchingProperty: "text",
                searchFor: value,
                needIndex: true,
              });
              setValues({
                ...values,
                dynamicReceipt: {
                  ...dynamicReceipt,
                  year: index !== "-1" ? yearList[index].key : "",
                },
              });
            }}
          />
        </div>
        <div style={{ height: "50px" }}/>
        <Form>
        <FormField>
            <label>Note</label>
            <input
             placeholder="Write your note here"
             onChange={(e)=>{
                 setValues({
                     ...values,
                    dynamicReceipt:{
                      ...dynamicReceipt,
                      note:e.target.value
                    }
                 })
             }}
            />
        </FormField>
        </Form>
        {message && <p>{message}</p>}
        <div style={{ height: "150px" }}/>
      </Modal.Content>
      
      <Modal.Actions>
        <Button
        loading={generatePdfLoading}
          color="brown"
          onClick={() =>
            onSubmit({ mode: "create", data: dynamicReceipt })
          }
        >
          Create Receipt
        </Button>
        <Button color="red" onClick={() => onModalClose()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CreateDynamicReceiptModal;
