import { useEffect, useState } from "react";
import { Modal, Button, Form } from "semantic-ui-react";


const CreateUpdateLandlordModal = ({ data, showModal, onModalClose, onSubmit }) => {
    
    const [values, setValues] = useState({
        showModal:showModal,
        landlordData: {
          name: "",
          is_verified: false,
        }
      });

    const {landlordData} = values;


    useEffect(()=>{
        data &&  setValues({
            ...values,
            landlordData:data
        })
    },[data])

    const setLandlordData = ({ property, data }) => {
        setValues({
          ...values,
          landlordData: {
            ...landlordData,
            [property]: data,
          },
        });
      };

    return (
        <Modal
        open={showModal}
        onClose={() =>onModalClose()}
    
      >
        <Modal.Header>
          <h3>{data?"Update landlord":"Add new Landlord"}</h3>
        </Modal.Header>

        <Modal.Content scrolling>
          <Modal.Description>
            <Form>
              <Form.Field required>
                <label>Landlord name</label>
                <input
                  placeholder="name...."
                  defaultValue={data && data.name}
                  onChange={(e) =>
                    setLandlordData({
                      property: "name",
                      data: e.target.value,
                    })
                  }
                />
              </Form.Field>
              <Form.Group>
                  
                  <Form.Field
                    label="Is verified?(chekck! if yes)"
                    control="input"
                    type="checkbox"
                    checked={landlordData.is_verified}
                    onChange={(e) =>
                      setLandlordData({
                        property: "is_verified",
                        data: e.target.checked,
                      })
                    }
                  ></Form.Field>
                </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
        <Button color="red" onClick={() => onModalClose()}>Cancel</Button>
        <Button
          color="brown"
          onClick={() =>
            onSubmit({ mode: data ? "edit" : "create", data:  landlordData  })
          }
        >
          {data ? "Update landlord" : "Create landlord"}
        </Button>
      </Modal.Actions>
      </Modal>
    )
}

export default CreateUpdateLandlordModal
