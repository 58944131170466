import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "semantic-ui-react";
import { getLocalStorage, setLocalStorage } from "../../../utilities/session";
import { baseUrl } from "../../../services/api/apiClient";
import Cookies from "js-cookie";

const ProfileFragment = () => {
  const history = useHistory();

  const [password, setPassword] = useState("");
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!password) {
      alert("Complete the fields");
      return;
    }
    try {
      const response = await fetch(baseUrl + "changepassword", {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: JSON.stringify({
          password,
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        alert(data?.error?.message ?? "Something went wrong");
        return;
      } else {
        alert("Password changed successfully");
      }
    } catch (e) {
      alert("Something went wrong");
    }
  };
  return (
    <div className="parentContainer">
      {/* <p>{getLocalStorage("user", true)?.name ?? "[n/a]"}</p> */}
      {/* <p>{getLocalStorage("user", true)?.mobile ?? "[n/a]"}</p> */}
      <Form>
        <Form.Field>
          <label>Name</label>
          <input
            disabled
            placeholder="name"
            value={getLocalStorage("user", true)?.name}
          />
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <input
            disabled
            placeholder="email"
            value={getLocalStorage("user", true)?.mail}
          />
        </Form.Field>

        <Form.Field>
          <label>New password</label>
          <input
            placeholder="Password"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Field>
        <Form.Button onClick={onSubmit}>Change password</Form.Button>
      </Form>
    </div>
  );
};

export default ProfileFragment;
