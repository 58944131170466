export const toQueryString = (object) => {
  if(!object){return ""}
  else{
    object = Object.entries(object).reduce(
      (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
      {}
    );
    return (
      "?" +
      Object.keys(object)
        .map((key) => `${key}=${object[key].toString()}`)
        .join("&")
    );
  }
};
