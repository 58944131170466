import axios from "axios";
import Cookies from "js-cookie";
import { toQueryString } from "../../utilities/helper";

export const baseUrl =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_API_PRODUCTION
    : process.env.REACT_APP_API_DEVELOPMENT;

export const getData = async ({ collectionName, params = undefined }) => {

  const token = Cookies.get("token");
  const url = baseUrl + collectionName + toQueryString(params);
  console.log("url", url)
  const bearerToken = "Bearer " + token;

  const res = await axios.get(url, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: bearerToken,
    },
  })
  return res.data

}

export const postData = async ({ collectionName, data, params = undefined }) => {

  console.log("will post data", data)

  const token = Cookies.get("token");
  const url = baseUrl + collectionName;
  const bearerToken = "Bearer " + token;

  const res = await axios.post(`${url}${toQueryString(params)}`, data, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerToken,
    }
  })
  return res

}

export const putData = async ({ collectionName, data, params = undefined }) => {

  console.log("data", data)

  const token = Cookies.get("token");
  const url = baseUrl + collectionName;
  const bearerToken = "Bearer " + token;

  const res = await axios.put(`${url}${toQueryString(params)}`, data, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerToken,
    }
  })
  return res

}

export const deleteData = async ({ collectionName, params = undefined }) => {
  const token = Cookies.get("token");
  const url = baseUrl + collectionName;
  const bearerToken = "Bearer " + token;

  const res = await axios.delete(`${url}${toQueryString(params)}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerToken,
    }
  })

  return res

}
