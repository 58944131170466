import {
  BUILDING_SET_LIST,
  BUILDING_TOOGLE_LOADING,
} from "../../utilities/constants";

const INIT_STATE = {
  loading: false,
  buildings: [],
  message:undefined
};
const buildingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BUILDING_SET_LIST:
      return {
        ...state,
        buildings: action.payload,
      };
    case BUILDING_TOOGLE_LOADING:
        return {
            ...state,
            loading:action.payload
        }
    default:
      return state;
  }
};

export default buildingReducer;
