import { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { FiAperture, FiAlignJustify, FiX } from "react-icons/fi";
import "./Navbar.css";
import { NavLink, useHistory } from "react-router-dom";
import {
  getCookie,
  getLocalStorage,
  removeCookie,
  removeLocalStorage,
} from "../../../utilities/session";
import * as actionCreators from "../../../redux/actions/index";

const Navbar = () => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    toogleDropdown: false,
  });
  const { toogleDropdown } = values;

  const history = useHistory();

  useEffect(() => {
    if (!getCookie("token") && !getLocalStorage("user")) {
      history.push("/");
    }
  }, []);

  const navbarItems = [
    {
      isButton: false,
      id: "renters",
      title: "Renters",
      url: "/renters",
      className: "nav--links",
    },
    {
      isButton: false,
      id: "buildings",
      title: "Buildings",
      url: "/buildings",
      className: "nav--links",
    },
    {
      isButton: false,
      id: "landlords",
      title: "Landlords",
      url: "/landlords",
      className: "nav--links",
    },
    {
      isButton: false,
      id: "readings",
      title: "Readings",
      url: "/readings",
      className: "nav--links",
    },
    {
      isButton: false,
      id: "receipts",
      title: "Receipts",
      url: "/receipts",
      className: "nav--links",
    },
    {
      isButton: false,
      id: "dynamicReceipts",
      title: "Dynamic Receipts",
      url: "/dynamic-receipts",
      className: "nav--links",
    },
    {
      isButton: false,
      id: "profile",
      title: "Profile",
      url: "/profile",
      className: "nav--links",
    },
    {
      isButton: true,
      id: "logout",
      title: "Log out",
    },
  ];

  return (
    <div className="navbarContainer">
      <div
        className="navbar__menu"
        onClick={() =>
          setValues({ ...values, toogleDropdown: !toogleDropdown })
        }
      >
        {toogleDropdown ? <FiX /> : <FiAlignJustify />}
      </div>
      <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
        <img style={{ width: 32 }} src="/logo192.png" />
      </div>
      <ul className={toogleDropdown ? "navbar__links active" : "navbar__links"}>
        {navbarItems.map((item) => (
          <li>
            {!item.isButton ? (
              <NavLink
                to={item.url}
                className={item.className}
                activeClassName={item.className + " active"}
                onClick={() =>
                  setValues({ ...values, toogleDropdown: !toogleDropdown })
                }
              >
                {item.title}
              </NavLink>
            ) : (
              <Button
                color="brown"
                onClick={() =>
                  dispatch(actionCreators.toogleLogOutConfirmation(true))
                }
              >
                Logout
              </Button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navbar;
