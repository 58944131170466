import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Confirm, Dimmer, Dropdown, Loader } from "semantic-ui-react";
import ActivityPanel from "../../components/ActivityPanel";
import ItemViewer from "../../components/ItemViewer";
import * as actionCreators from "../../../redux/actions/index";
import CreateUpdateReading from "./CreateUpdateReadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DetailsViewerModal from "./DetailsViewerModal";
import { monthListBangla } from "../../core/listData";
import {
  generateDropdownArray,
  generateIncArrayAsDropdown,
} from "../../core/helpingFunctions";
import { toQueryString } from "../../../utilities/helper";
import { useHistory } from "react-router-dom";

const ReadingsFragments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, readings } = useSelector((state) => state.readings);
  const { buildings } = useSelector((state) => state.buildings);
  const [query, setQuery] = useState({ limit: 20, skip: 0 });
  const [pageNumber, setPageNumber] = useState(0);

  const [values, setValues] = useState({
    readingsListTemp: [],
    showEditModal: false,
    showViewModal: false,
    showDeletePopUp: false,
    selectMultiple: false,
    readingToBeUpdated: undefined,
    readingToBeShown: undefined,
    itemsTobeDeleted: {},
  });

  const {
    readingsListTemp,
    showEditModal,
    showViewModal,
    readingToBeUpdated,
    readingToBeShown,
    showDeletePopUp,
    itemsTobeDeleted,
    selectMultiple,
  } = values;

  const setData = () => {
    setValues({
      ...values,
      readingsListTemp: readings,
    });
  };
  useEffect(() => {
    // dispatch(actionCreators.getReadings());
    dispatch(actionCreators.getBuildings());
  }, []);

  useEffect(() => {
    console.log("query effect");
    const queryParams = toQueryString(query);
    history.push(`/readings${queryParams}`);
    dispatch(actionCreators.getReadings(query));
  }, [query]);

  useEffect(() => {
    setData();
    console.log("readings effect");
  }, [readings]);

  const onModalClose = () => {
    setValues({
      ...values,
      showEditModal: false,
      showViewModal: false,
      readingToBeUpdated: undefined,
      readingToBeShown: undefined,
    });
  };
  const createNewReading = ({ data }) => {
    console.log("Dispatching createReading");
    dispatch(actionCreators.createReading(data));
  };
  const updateReadingData = ({ data }) => {
    console.log("Dispatching updateReading");
    dispatch(actionCreators.updateReading(data));
  };

  const onCreateOrEdit = ({ mode, data }) => {
    if (
      data.building === "" ||
      data.month === 0 ||
      data.year === 0 ||
      data.reading_type === "" ||
      data.meter_type === ""
    ) {
      toast("Please fill the required forms");
    } else {
      onModalClose();
      switch (mode) {
        case "create":
          createNewReading({ data: data });
          return;
        case "edit":
          updateReadingData({ data: data });
          return;
        default:
          console.log(mode);
          console.log(data);
      }
    }
  };

  const onAddClick = () => {
    console.log("clicked");
    setValues({
      ...values,
      showEditModal: true,
    });
  };

  const onItemClick = (index) => {
    if (selectMultiple) {
      let items = { ...itemsTobeDeleted };
      if (itemsTobeDeleted[index]) {
        delete items[index];
      } else {
        items = { ...itemsTobeDeleted, [index]: readingsListTemp[index]._id };
      }
      setValues({
        ...values,
        itemsTobeDeleted: items,
      });
    } else {
      setValues({
        ...values,
        readingToBeShown: readingsListTemp[index],
        showViewModal: true,
      });
    }
    console.log("item click", index);
  };

  const onEditItem = (index) => {
    console.log("edit click", index);
    setValues({
      ...values,
      readingToBeUpdated: readingsListTemp[index],
      showEditModal: true,
    });
  };

  const onDeleteItem = () => {
    console.log("delete confirmed", itemsTobeDeleted);
    if (Object.entries(itemsTobeDeleted).length === 0) {
      toast("No item selected");
    } else {
      let deleteIds = [];
      for (const [key, value] of Object.entries(itemsTobeDeleted)) {
        deleteIds = [...deleteIds, value];
        // console.log(key,value)
      }
      dispatch(actionCreators.deleteReadingAsList(deleteIds));
      console.log("delete ids", deleteIds);
      setValues({
        ...values,
        showDeletePopUp: false,
        itemsTobeDeleted: {},
        selectMultiple: false,
      });
    }
  };

  // const onSearch = (text) => {
  //   if (text === "" || text == null) {
  //     setData();
  //   } else {
  //     text = text.toLowerCase();
  //     setValues({
  //       ...values,
  //       readingsListTemp: readings.filter((reading) => {
  //         var res1 = reading.reading_type.toLowerCase().match(text);
  //         var res2 = reading.building.name.toLowerCase().match(text);
  //         // console.log(res1)
  //         return res1 == null && res2 == null ? false : true;
  //       }),
  //     });
  //   }
  //   // console.log(search)
  // };
  const onSearch = ({ key, value }) => {
    setQuery({
      ...query,
      [key]: value,
    });
  };

  // const onFilterBuilding = (value) => {
  //   if (value === "" || value == null) {
  //     setData();
  //   } else {
  //     value = value.toLowerCase();
  //     setValues({
  //       ...values,
  //       readingsListTemp: readings.filter((reading) => {
  //         var res1 = reading?.building?.name?.toLowerCase().match(value);
  //         // console.log(res1)
  //         return res1 == null ? false : true;
  //       }),
  //     });
  //   }
  // };

  const buildPrimaryText = (reading) => {
    const { month, year, building, reading_type } = reading;
    return `${monthListBangla[month - 1]}, ${year} - ${
      reading_type == "electricity" ? "বিদ্যুৎ" : "গ্যাস"
    } - ${building?.name}`;
  };

  const buildSecondaryText = (reading) => {
    const { entries, meter_type, building } = reading;
    const { store_count, flat_count } = building || {};
    console.log({ flat_count, store_count, entries: entries.length });
    return `মিটারের ধরনঃ ${meter_type}, এন্ট্রি দেয়া হয়েছেঃ ${
      entries?.length
    } টি, বাকি আছেঃ ${store_count + flat_count - entries?.length} টি।`;
  };

  return (
    <div className="parentContainer">
      <ActivityPanel
        title="Readings"
        onAddClick={onAddClick}
        onChange={onSearch}
        hideSearch
        extra={
          <>
            <Dropdown
              placeholder="Select building"
              fluid
              search
              selection
              clearable
              onChange={(e, { value }) => {
                onSearch({ key: "building", value: value });
              }}
              options={buildings?.map((item, index) => ({
                text: item?.name,
                value: item?._id,
                key: item?._id,
              }))}
            />
            <Dropdown
              placeholder="Select Month"
              fluid
              search
              selection
              clearable
              onChange={(e, { value }) => {
                onSearch({ key: "month", value: value });
              }}
              options={generateDropdownArray({
                textArray: monthListBangla,
                startValue: 1,
              })}
            />
            <Dropdown
              placeholder="Choose year"
              fluid
              search
              selection
              clearable
              onChange={(e, { value }) => {
                onSearch({ key: "year", value: value });
              }}
              options={generateIncArrayAsDropdown({ start: 2020, size: 20 })}
            />
          </>
        }
        placeholder="Search by building/type..."
      />
      <div style={{ alignSelf: "flex-start" }}>
        {selectMultiple && (
          <Button
            onClick={() => {
              setValues({
                ...values,
                selectMultiple: false,
                itemsTobeDeleted: {},
              });
            }}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={() => {
            if (selectMultiple) {
              setValues({ ...values, showDeletePopUp: true });
            } else {
              setValues({ ...values, selectMultiple: !selectMultiple });
            }
          }}
          color={selectMultiple && "red"}
        >
          {selectMultiple ? "Delete all" : "Delete multiple"}
        </Button>
      </div>
      <div style={{ height: "50px", alignSelf: "flex-end" }}>
        {!selectMultiple && (
          <div>
            <Button
              disabled={pageNumber === 0}
              onClick={() => {
                setPageNumber(pageNumber !== 0 ? pageNumber - 1 : 0);
                setQuery({ ...query, skip: pageNumber * query.limit });
              }}
            >
              Previous
            </Button>
            <Button
              disabled={readings.length === 0 || readings.length < query.limit}
              onClick={() => {
                readings.length !== 0 && setPageNumber(pageNumber + 1);
                setQuery({ ...query, skip: (pageNumber + 1) * query.limit });
              }}
            >
              Next
            </Button>
          </div>
        )}
      </div>
      {loading ? (
        <span class="loader"></span>
      ) : readingsListTemp.length === 0 ? (
        <p>No readings to show</p>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {readingsListTemp.map((reading, index) => (
            <ItemViewer
              index={index + pageNumber * query.limit}
              primaryText={buildPrimaryText(reading)}
              selectMultiple={selectMultiple}
              isSelected={itemsTobeDeleted[index] ? true : false}
              secondaryText={buildSecondaryText(reading)}
              onItemClick={onItemClick}
              onEdit={onEditItem}
              onDelete={() => {
                setValues({
                  ...values,
                  itemsTobeDeleted: {
                    ...itemsTobeDeleted,
                    [index]: reading._id,
                  },
                  showDeletePopUp: true,
                });
              }}
            />
          ))}
        </div>
      )}
      {showEditModal && (
        <CreateUpdateReading
          showModal={showEditModal}
          onModalClose={onModalClose}
          data={readingToBeUpdated}
          onSubmit={onCreateOrEdit}
          buildings={buildings}
        />
      )}

      {showViewModal && (
        <DetailsViewerModal
          showModal={showViewModal}
          onModalClose={onModalClose}
          reading={readingToBeShown}
          buildings={buildings}
        />
      )}

      <Dimmer active={false}>
        <Loader>Working on it</Loader>
      </Dimmer>
      <ToastContainer />
      <Confirm
        open={showDeletePopUp}
        confirmButton="Yes,delete "
        cancelButton="No,leave it"
        onConfirm={() => onDeleteItem()}
        onCancel={() =>
          setValues({
            ...values,
            itemsTobeDeleted: {},
            showDeletePopUp: false,
            selectMultiple: false,
          })
        }
      />
    </div>
  );
};

export default ReadingsFragments;
