import { useEffect, useState } from "react";
import { Modal, Button, Form, Card } from "semantic-ui-react";
import {
  findValueByProperty,
  generateDropdownArray,
} from "../../core/helpingFunctions";
import { flatListBangla } from "../../core/listData";

const CreateUpdateModal = ({
  data,
  showModal,
  onModalClose,
  onSubmit,
  landlords = [],
}) => {
  // console.log(data)

  const [values, setValues] = useState({
    showModal: showModal,
    landlordInitiated: false,
    selectedLandlordValue: "-1",
    landlordList: [],
    buildingData: {
      name: "",
      landlord: "",
      address: "",
      hotline: "",
      floor_count: 0,
      flat_count: 0,
      store_count: 0,
      flat_electricity_unit_price: 0,
      store_electricity_unit_price: 0,
      gas_unit_price: -1,
      flat_info: [],
      template: "",
      snippets: [],
    },
  });
  const {
    buildingData,
    selectedLandlordValue,
    landlordList,
    landlordInitiated,
  } = values;

  //landlord list generation once
  const createLandlordData = () => {
    let landlordNames = [];
    landlords.forEach((landlord) => {
      landlordNames.push(landlord.name);
    });
    setValues({
      ...values,
      landlordList: generateDropdownArray({
        textArray: landlordNames,
        useLowerCaseValue: false,
      }),
      landlordInitiated: true,
    });
  };

  !landlordInitiated && createLandlordData();

  useEffect(() => {
    if (data) {
      console.log("setValues called", data);
      setValues({
        ...values,
        buildingData: { ...data, snippets: data?.snippets ?? [] },

        selectedLandlordValue: data.landlord.name,
      });
    }
    // console.log("use effect called")
  }, [data]);

  useEffect(() => {
    if (selectedLandlordValue !== "-1") {
      setNewBuildingData({
        property: "landlord",
        data: findValueByProperty({
          array: landlords,
          searchingProperty: "name",
          searchFor: selectedLandlordValue,
          resultProperty: "_id",
        }),
      });
    }
  }, [selectedLandlordValue]);

  // console.log("building data",buildingData)

  const setNewBuildingData = ({ property, data }) => {
    setValues({
      ...values,
      buildingData: {
        ...buildingData,
        [property]: data,
      },
    });
  };

  const updateEntry = ({ index, property, data }) => {
    let snippets = [...buildingData.snippets];
    snippets[index] = {
      ...snippets[index],
      [property]: data,
    };
    setValues({
      ...values,
      buildingData: {
        ...buildingData,
        snippets: snippets,
      },
    });
  };

  const deleteEntry = (index) => {
    let snippets = [...buildingData.snippets];
    snippets.splice(index, 1);
    // console.log("After delete", snippets);
    setValues({
      ...values,
      buildingData: {
        ...buildingData,
        snippets: snippets,
      },
    });
  };

  return (
    <div>
      <Modal open={showModal} onClose={() => onModalClose()}>
        <Modal.Header>
          <h3>{data ? "Update building data" : "Add new building"}</h3>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Form>
              <Form.Group widths="two">
                <Form.Field required>
                  <label>Building name</label>
                  <input
                    placeholder="Building name ...."
                    defaultValue={data ? data.name : ""}
                    onChange={(e) =>
                      setNewBuildingData({
                        property: "name",
                        data: e.target.value,
                      })
                    }
                  />
                </Form.Field>

                <Form.Dropdown
                  label="Landlord"
                  placeholder="Choose landlord"
                  selection
                  required
                  options={landlordList}
                  value={selectedLandlordValue}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      selectedLandlordValue: e.target.innerText,
                    })
                  }
                />
              </Form.Group>

              <Form.Group widths="two">
                <Form.Field required>
                  <label>Address</label>
                  <input
                    placeholder="address ...."
                    defaultValue={data ? data.address : ""}
                    onChange={(e) =>
                      setNewBuildingData({
                        property: "address",
                        data: e.target.value,
                      })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <label>Hotline</label>
                  <input
                    placeholder="0"
                    defaultValue={data ? data.hotline : ""}
                    onChange={(e) =>
                      setNewBuildingData({
                        property: "hotline",
                        data: e.target.value,
                      })
                    }
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field>
                  <label>Template Name</label>
                  <input
                    placeholder="template.pdf"
                    defaultValue={data?.template || ""}
                    onChange={(e) => {
                      setNewBuildingData({
                        property: "template",
                        data: e.target.value,
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Text Color</label>
                  <input
                    placeholder="0.3,0.6,0.12"
                    defaultValue={data && data.color}
                    onChange={(e) => {
                      let arr = e.target.value.trim().split(",");
                      setNewBuildingData({
                        property: "color",
                        data: arr,
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Floor count</label>
                  <input
                    placeholder="0"
                    type="number"
                    defaultValue={data ? data.floor_count : ""}
                    onChange={(e) =>
                      setNewBuildingData({
                        property: "floor_count",
                        data: e.target.value,
                      })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <label>Flat count</label>
                  <input
                    placeholder="0"
                    type="number"
                    defaultValue={data ? data.flat_count : ""}
                    onChange={(e) =>
                      setNewBuildingData({
                        property: "flat_count",
                        data: e.target.value,
                      })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <label>Store count</label>
                  <input
                    placeholder="0"
                    type="number"
                    defaultValue={data ? data.store_count : ""}
                    onChange={(e) =>
                      setNewBuildingData({
                        property: "store_count",
                        data: e.target.value,
                      })
                    }
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field>
                  <label>Electricity Unit price (Flat)</label>
                  <input
                    placeholder="0"
                    type="number"
                    defaultValue={data ? data.flat_electricity_unit_price : ""}
                    onChange={(e) =>
                      setNewBuildingData({
                        property: "flat_electricity_unit_price",
                        data: e.target.value,
                      })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <label>Electricity Unit price (Store)</label>
                  <input
                    placeholder="0"
                    type="number"
                    defaultValue={data ? data.store_electricity_unit_price : ""}
                    onChange={(e) =>
                      setNewBuildingData({
                        property: "store_electricity_unit_price",
                        data: e.target.value,
                      })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <label>Gas Unit price</label>
                  <input
                    placeholder="0"
                    type="number"
                    defaultValue={data ? data.gas_unit_price : ""}
                    onChange={(e) =>
                      setNewBuildingData({
                        property: "gas_unit_price",
                        data: e.target.value,
                      })
                    }
                  />
                </Form.Field>
              </Form.Group>

              <Form.Field>
                <label>Flat info (multiple - comma seperated)</label>
                <input
                  placeholder="info1,info2,...."
                  defaultValue={data && data.flat_info}
                  onChange={(e) => {
                    let info = e.target.value.trim().split(",");
                    setNewBuildingData({
                      property: "flat_info",
                      data: info,
                    });
                  }}
                />
              </Form.Field>
              <br />
              {!data ? (
                <></>
              ) : buildingData?.snippets?.length === 0 ? (
                <p>No snippets found</p>
              ) : (
                buildingData?.snippets?.map((entry, index) => (
                  <Card fluid>
                    <Card.Content>
                      <Form.Group widths="equals">
                        <Form.Dropdown
                          label="Floor"
                          required
                          selection
                          placeholder="Choose floor"
                          options={generateDropdownArray({
                            textArray: flatListBangla.slice(
                              0,
                              buildingData.floor_count
                            ),
                            startValue: 0,
                            useLowerCaseValue: false,
                            useValueAsKey: true,
                          })}
                          defaultValue={
                            data &&
                            index < buildingData?.snippets?.length &&
                            (entry.floor < buildingData.floor_count
                              ? flatListBangla[entry.floor]
                              : "")
                          }
                          onChange={(e, { value }) =>
                            updateEntry({
                              index: index,
                              property: "floor",
                              data: flatListBangla.indexOf(value),
                            })
                          }
                        ></Form.Dropdown>
                        <Form.Dropdown
                          label="Flat"
                          required
                          placeholder="Choose flat"
                          selection
                          options={generateDropdownArray({
                            textArray: buildingData.flat_info,
                            useLowerCaseValue: false,
                          })}
                          defaultValue={
                            data &&
                            index < buildingData.snippets.length &&
                            (entry.flat < buildingData.flat_info.length
                              ? buildingData.flat_info[entry.flat]
                              : "")
                          }
                          onChange={(e) => {
                            updateEntry({
                              index: index,
                              property: "flat",
                              data: findValueByProperty({
                                array: generateDropdownArray({
                                  textArray: buildingData.flat_info,
                                  useLowerCaseValue: false,
                                }),
                                searchingProperty: "value",
                                searchFor: e.target.innerText,
                                needIndex: true,
                              }),
                            });
                          }}
                        ></Form.Dropdown>
                        <Form.Field>
                          <label>Used unit</label>
                          <input
                            placeholder="Used unit"
                            defaultValue={
                              data &&
                              index < buildingData.snippets.length &&
                              buildingData.snippets[index].used_unit
                            }
                            type="number"
                            value={entry.used_unit}
                            onChange={(e) =>
                              updateEntry({
                                index: index,
                                property: "used_unit",
                                data: e.target.value,
                              })
                            }
                          />
                        </Form.Field>

                        <Form.Checkbox
                          label="Is store?"
                          control="input"
                          checked={buildingData.snippets[index].is_store}
                          type="checkbox"
                          onChange={(e) =>
                            updateEntry({
                              index: index,
                              property: "is_store",
                              data: e.target.checked,
                            })
                          }
                        ></Form.Checkbox>
                        <Button color="red" onClick={() => deleteEntry(index)}>
                          Delete entry
                        </Button>
                      </Form.Group>
                    </Card.Content>
                  </Card>
                ))
              )}
              {data && (
                <Button
                  color="orange"
                  onClick={() =>
                    setValues({
                      ...values,
                      buildingData: {
                        ...buildingData,
                        snippets: buildingData.snippets.concat({
                          floor: 0,
                          flat: 0,
                          used_unit: 0,
                          is_store: false,
                        }),
                      },
                    })
                  }
                >
                  Add entry
                </Button>
              )}
            </Form>
          </Modal.Description>
        </Modal.Content>

        <Modal.Actions>
          <Button color="red" onClick={() => onModalClose()}>
            Cancel
          </Button>
          <Button
            color="brown"
            onClick={() =>
              onSubmit({ mode: data ? "edit" : "create", data: buildingData })
            }
          >
            {data ? "Update Building" : "Create building"}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default CreateUpdateModal;
