import { getData, postData, putData ,deleteData} from "./apiClient";

export const getBuildingsList = async (query) => {
  const data = await getData({collectionName:"buildings",params:query})
  return data
//   console.log(res.data)
};

export const postBuilding = async({data})=>{
  console.log("Post req called")
  return await postData({collectionName:"building",data:data})
}

export const putBuilding= async({data})=>{
  console.log("Put req called")
  const collectionName = "building/"+data._id
  return await putData({collectionName:collectionName,data:data})
}

export const deleteBuilding=async({data})=>{
  console.log("del req called")
  const collectionName = "building/"+data
  return await deleteData({collectionName:collectionName})
}

