import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dimmer, Loader, Confirm, Dropdown } from "semantic-ui-react";
import ActivityPanel from "../../components/ActivityPanel";
import ItemViewer from "../../components/ItemViewer";
import * as actionCreators from "../../../redux/actions/index";
import CreateUpdateModal from "./CreateUpdateRenterModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DetailsViewModal from "./DetailsViewModal";
import { generateDropdownArray } from "../../core/helpingFunctions";
import { flatListBangla } from "../../core/listData";
import { toQueryString } from "../../../utilities/helper";
import { useHistory } from "react-router-dom";

const RentersFragment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [query, setQuery] = useState({ limit: 20, skip: 0, is_active: true });

  const { loading, renters } = useSelector((state) => state.renters);
  const { buildings } = useSelector((state) => state.buildings);

  const [pageNumber, setPageNumber] = useState(0);

  const [values, setValues] = useState({
    rentersListTemp: [],
    showEditModal: false,
    showViewModal: false,
    showDeletePopUp: false,
    selectMultiple: false,
    renterToBeUpdated: undefined,
    renterToBeShown: undefined,
    itemsTobeDeleted: {},
  });

  const {
    rentersListTemp,
    showViewModal,
    showDeletePopUp,
    showEditModal,
    selectMultiple,
    renterToBeUpdated,
    renterToBeShown,
    itemsTobeDeleted,
  } = values;

  useEffect(() => {
    dispatch(actionCreators.getBuildings());
  }, []);

  useEffect(() => {
    const queryParams = toQueryString(query);
    history.push(`/renters${queryParams}`);
    dispatch(actionCreators.getRenters(query));
  }, [query]);

  const setData = () => {
    setValues({
      ...values,
      rentersListTemp: renters,
    });
  };

  useEffect(() => {
    renters && setData();
    console.log("renters effect");
  }, [renters]);

  const onModalClose = () => {
    setValues({
      ...values,
      showEditModal: false,
      showViewModal: false,
      renterToBeUpdated: undefined,
      renterToBeShown: undefined,
    });
  };
  const createNewRenter = ({ data }) => {
    console.log("Dispatching createRenter");
    dispatch(actionCreators.createRenter(data));
  };
  const updateRenterData = ({ data }) => {
    console.log("Dispatching updateRenter");
    dispatch(actionCreators.updateRenter(data));
  };

  const onCreateOrEdit = ({ mode, data }) => {
    if (data.name === "" || data.building === "") {
      toast("Please fill the required fields");
    } else {
      onModalClose();
      switch (mode) {
        case "create":
          createNewRenter({ data: data });
          return;
        case "edit":
          updateRenterData({ data: data });
          return;
        default:
          console.log(mode);
          console.log(data);
          return;
      }
    }
  };

  const onAddClick = () => {
    setValues({
      ...values,
      showEditModal: true,
    });
  };

  const onItemClick = (index) => {
    if (selectMultiple) {
      let items = { ...itemsTobeDeleted };
      if (itemsTobeDeleted[index]) {
        delete items[index];
      } else {
        items = { ...itemsTobeDeleted, [index]: rentersListTemp[index]._id };
      }
      setValues({
        ...values,
        itemsTobeDeleted: items,
      });
    } else {
      setValues({
        ...values,
        renterToBeShown: rentersListTemp[index],
        showViewModal: true,
      });
    }
    console.log("item click", index);
  };

  const onEditItem = (index) => {
    setValues({
      ...values,
      renterToBeUpdated: rentersListTemp[index],
      showEditModal: true,
    });
    console.log("edit click", index);
  };

  const onDeleteItem = (index) => {
    console.log("delete confirmed", itemsTobeDeleted);
    if (Object.entries(itemsTobeDeleted).length === 0) {
      toast("No item selected");
    } else {
      let deleteIds = [];
      for (const [key, value] of Object.entries(itemsTobeDeleted)) {
        deleteIds = [...deleteIds, value];
        // console.log(key,value)
      }
      dispatch(actionCreators.deleteRenterAsList(deleteIds));
      console.log("delete ids", deleteIds);
      setValues({
        ...values,
        showDeletePopUp: false,
        itemsTobeDeleted: {},
        selectMultiple: false,
      });
    }
  };

  const onSearch = ({ key, value }) => {
    setQuery({
      ...query,
      [key]: value,
    });
  };

  // const onSearch = (text) => {
  //   // console.log(text)
  //   if (text === "" || text == null) {
  //     setData();
  //   } else {
  //     text = text.toLowerCase();
  //     setValues({
  //       ...values,
  //       rentersListTemp: renters.filter((renter) => {
  //         var res1 = renter.name.toLowerCase().match(text);
  //         var res2 = renter.building.name.toLowerCase().match(text);
  //         // console.log(res1)
  //         return res1 == null && res2 == null ? false : true;
  //       }),
  //     });
  //   }
  //   // console.log(search)
  // };

  // const onFilterBuilding = (value) => {
  //   if (value === "" || value == null) {
  //     setData();
  //   } else {
  //     value = value.toLowerCase();
  //     setValues({
  //       ...values,
  //       rentersListTemp: renters.filter((renter) => {
  //         var res1 = renter?.building?.name?.toLowerCase().match(value);
  //         // console.log(res1)
  //         return res1 == null ? false : true;
  //       }),
  //     });
  //   }
  // };

  const buildPrimaryText = (renter) => {
    const { name, building, floor, flat, is_store, is_active } = renter;

    return `${!is_active ? "[সচল নেই] " : ""}${name} (${building.name} - ${
      flatListBangla[floor]
    } তলা, ${building?.flat_info?.[flat]}, ${is_store ? "দোকান" : "ফ্ল্যাট"})`;
  };

  const buildSecondaryText = (renter) => {
    const { bill_rent, bill_gas, bill_dirt, previous_due, no_extra_bill } =
      renter;
    return `${
      no_extra_bill ? "(অতিরিক্ত বিল নেই) " : ""
    }বকেয়াঃ ${previous_due} টাকা, ভাড়াঃ ${bill_rent || 0} টাকা, গ্যাসঃ ${
      bill_gas || 0
    } টাকা, ময়লাঃ ${bill_dirt || 0} টাকা।`;
  };

  return (
    <div className="parentContainer">
      <ActivityPanel
        title="Renters"
        onAddClick={onAddClick}
        onChange={(value) => onSearch({ key: "name", value: value })}
        placeholder="Search by renters name..."
        extra={
          <>
            <Dropdown
              placeholder="Select status"
              fluid
              search
              selection
              clearable
              value={query?.is_active ?? undefined}
              onChange={(e, { value }) => {
                // onFilterBuilding(value);
                onSearch({ key: "is_active", value: value });
              }}
              options={[
                {
                  text: "[সচল নেই]",
                  value: false,
                  key: "inactive",
                },
                {
                  text: "[সচল আছে]",
                  value: true,
                  key: "active",
                },
              ]}
            />

            <Dropdown
              placeholder="Select building"
              fluid
              search
              selection
              clearable
              value={query?.building ?? undefined}
              onChange={(e, { value }) => {
                // onFilterBuilding(value);
                onSearch({ key: "building", value: value });
              }}
              options={buildings?.map((item, index) => ({
                text: item?.name,
                value: item?._id,
                key: item?._id,
              }))}
            />
          </>
        }
      />
      <div style={{ alignSelf: "flex-start" }}>
        {selectMultiple && (
          <Button
            onClick={() => {
              setValues({
                ...values,
                selectMultiple: false,
                itemsTobeDeleted: {},
              });
            }}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={() => {
            if (selectMultiple) {
              setValues({ ...values, showDeletePopUp: true });
            } else {
              setValues({ ...values, selectMultiple: !selectMultiple });
            }
          }}
          color={selectMultiple && "red"}
        >
          {selectMultiple ? "Delete all" : "Delete multiple"}
        </Button>
      </div>
      <div className="paginator">
        {!selectMultiple && (
          <div>
            <Button
              disabled={pageNumber === 0}
              onClick={() => {
                setPageNumber(pageNumber !== 0 ? pageNumber - 1 : 0);
                setQuery({ ...query, skip: pageNumber * query.limit });
              }}
            >
              Previous
            </Button>
            <Button
              disabled={renters.length === 0 || renters.length < query.limit}
              onClick={() => {
                renters.length !== 0 && setPageNumber(pageNumber + 1);
                setQuery({ ...query, skip: (pageNumber + 1) * query.limit });
              }}
            >
              Next
            </Button>
          </div>
        )}
      </div>
      {loading ? (
        <span class="loader"></span>
      ) : rentersListTemp.length === 0 ? (
        <p>No renter to show</p>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {rentersListTemp.map((renter, index) => (
            <ItemViewer
              index={index + pageNumber * query.limit}
              primaryText={buildPrimaryText(renter)}
              selectMultiple={selectMultiple}
              isSelected={itemsTobeDeleted[index] ? true : false}
              secondaryText={buildSecondaryText(renter)}
              onItemClick={onItemClick}
              onEdit={onEditItem}
              onDelete={() => {
                setValues({
                  ...values,
                  itemsTobeDeleted: {
                    ...itemsTobeDeleted,
                    [index]: renter._id,
                  },
                  showDeletePopUp: true,
                });
              }}
            />
          ))}
        </div>
      )}
      {showEditModal && (
        <CreateUpdateModal
          showModal={showEditModal}
          onModalClose={onModalClose}
          data={renterToBeUpdated}
          onSubmit={onCreateOrEdit}
          buildings={buildings}
        />
      )}
      {showViewModal && (
        <DetailsViewModal
          showModal={showViewModal}
          onModalClose={onModalClose}
          renter={renterToBeShown}
        />
      )}

      <Dimmer active={false}>
        <Loader>Working on it</Loader>
      </Dimmer>
      <ToastContainer />
      <Confirm
        open={showDeletePopUp}
        confirmButton="Yes,delete "
        cancelButton="No,leave it"
        onConfirm={() => onDeleteItem()}
        onCancel={() =>
          setValues({
            ...values,
            itemsTobeDeleted: {},
            showDeletePopUp: false,
            selectMultiple: false,
          })
        }
      />
    </div>
  );
};

export default RentersFragment;
