export const generateDropdownArray = ({
  textArray = [],
  startValue = undefined,
  useValueAsKey = true,
  key = [],
  useLowerCaseValue=true,
}) => {
  var resultArray = [];
  textArray.forEach((text, index) => {
    resultArray.push({
      key: useValueAsKey ? (startValue ? startValue : index) : key[index],
      text: text,
      value: startValue ? startValue : useLowerCaseValue? text.toLowerCase():text,
    });
    if (startValue) {
      startValue += 1;
    }
  });
  return resultArray;
};

export const findValueByProperty = ({
  array = [],
  searchingProperty = "",
  searchFor = "",
  resultProperty = undefined,
  needIndex = false,
  initValue = "-1",
  enableLowerCaseSearch = false,
}) => {
  var result = initValue;
  enableLowerCaseSearch
    ? array.forEach((child, index) => {
        if (child[searchingProperty].toLowerCase() === searchFor) {
          result = needIndex ? index : child[resultProperty];
          return;
        }
      })
    : array.forEach((child, index) => {
        if (child[searchingProperty] === searchFor) {
          result = needIndex ? index : child[resultProperty];
          return;
        }
      });

  return result;
};

export const generateIncArrayAsDropdown=({start=0,size=0})=>{
  let array=[]
  for(let i=start;size--;i++){
    array.push({key:i,text:i.toString(),value:i.toString()})
  }
  // console.log("array inc", array)
  return array;
}
