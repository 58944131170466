import { Button, Modal } from "semantic-ui-react";
import { flatListBangla } from "../../core/listData";

const DetailsViewModal = ({
    showModal,
    renter= undefined,
    onModalClose,
}) => {
    console.log(renter)
    return (
        <Modal open={showModal} onClose={() => onModalClose()}>
        <Modal.Header>
            <h2>Renter details</h2>
        </Modal.Header>
      <Modal.Content scrolling>
        {renter && (
          <div>
            <p>{"Renter name :  " + renter.name}</p>
            <p>{"Building :  " + renter.building.name}</p>
            <p>{"Mobile:  " + renter.mobile}</p>
            <p>{"Floor :  " + flatListBangla[renter.floor]}</p>
            <p>{"Flat :  " + renter.building?.flat_info?.[renter.flat]}</p>
            <p>{"Bill(dirt) :  " + renter.bill_dirt}</p>
            <p>{"Bill(gas) :  " + renter.bill_gas}</p>
            <p>{"Bill(rent) :  " + renter.bill_rent}</p>
            <p>{"No extra bill :  " + renter.no_extra_bill}</p>
            <p>{"Is store :  " + renter.is_store}</p>
             <p>{"Created at :  " + renter.createdAt.substring(0,10)}</p>
            <p>{"Updated at :  " + renter.updatedAt.substring(0,10)}</p>
            <p>{"Updated by :  " + renter.updated_by.name}</p> 
          </div>
        )}

      </Modal.Content>
      <Modal.Actions>
          <Button color="red" onClick={()=>onModalClose()}>Cancel</Button>
      </Modal.Actions>
    </Modal>
    )
}

export default DetailsViewModal
