import { RENTERD_TOOGLE_LOADING, RENTER_SET_LIST } from '../../utilities/constants'

const INIT_STATE = {
    loading: false,
    renters: [],
    message:undefined
  };

const renterReducer = (state=INIT_STATE,action) => {

    switch(action.type){
        case RENTER_SET_LIST:
            return {
                ...state,
                renters:action.payload
            }
        case RENTERD_TOOGLE_LOADING:
            return{
                ...state,
                loading:action.payload
            }
        default:
            return state
    }
}

export default renterReducer
