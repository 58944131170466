import {call, put, takeLatest, fork, all} from  "redux-saga/effects"
import { BUILDING_CREATE, BUILDING_DELETE, BUILDING_GET_LIST, } from "../../utilities/constants";
import { postBuilding, getBuildingsList, putBuilding, deleteBuilding } from "../../services/api/Building"
import *as actionCreators from "../actions";
import { BUILDING_UPDATE } from "../../utilities/constants";

export function* getBuildings({payload}){
    yield put(actionCreators.toogleLoadingBuilding(true))
    try{
        const buildings = yield call(getBuildingsList,payload)
        yield put(actionCreators.setBuildings(buildings))
        yield put(actionCreators.toogleLoadingBuilding(false))
    }catch(error){
        console.log(error)
        yield put(actionCreators.toogleLoadingBuilding(false))
    }
    yield put(actionCreators.toogleLoadingBuilding(false))
}

export function* createBuilding({payload}){
    try{
        console.log("Response collected in saga", payload)
        yield put(actionCreators.toogleLoadingBuilding(true));
        const res = yield call(postBuilding,{data:payload})
        if(res.error){
            yield put(actionCreators.toogleLoadingBuilding(false))
            console.log(res.error)
        }else{
            console.log("Success", res)
            yield put(actionCreators.getBuildings())
        }
    }catch(error){
        console.log(error)
    }
}

export function* updateBuilding({payload}){
    try{
        console.log("Response collected in saga(update)", payload)
        yield put(actionCreators.toogleLoadingBuilding(true));
        const res = yield call(putBuilding,{data:payload})
        if(res.error){
            yield put(actionCreators.toogleLoadingBuilding(false))
            console.log(res.error)
        }else{
            console.log("Success", res)
            yield put(actionCreators.getBuildings())
        }
    }catch(error){
        console.log(error)
    }
}

export function* deleteBuildingAsList({payload}){
    try{
        console.log("Response collected in saga(delete)", payload)
        yield put(actionCreators.toogleLoadingBuilding(true))
        let res = yield all(payload.map((id)=>call(deleteBuilding,{data:id})))
        if(res.error){
            yield put(actionCreators.toogleLoadingBuilding(false))
            console.log(res.error)
        }else{
            console.log("Success", res)
            yield put(actionCreators.getBuildings())
        }
    }catch(error){
        console.log(error)
    }
}


export function* getBuildingsWatcher() {
    yield takeLatest(BUILDING_GET_LIST, getBuildings)
}
export function* postBuildingWatcher(){
    yield takeLatest(BUILDING_CREATE,createBuilding)
}
export function* putBuildingWatcher() {
    yield takeLatest(BUILDING_UPDATE,updateBuilding)
}
export function* deleteBuildingAsListWatcher(){
    yield takeLatest(BUILDING_DELETE,deleteBuildingAsList)
}

export default function* rootSaga() {
    yield all([
        fork(getBuildingsWatcher),
        fork(putBuildingWatcher),
        fork(postBuildingWatcher),
        fork(deleteBuildingAsListWatcher)
    ])
}