import { READING_SET_LIST, READING_TOOGLE_LOADING } from "../../utilities/constants"

const INIT_STATE = {
    loading: false,
    readings: [],
    message:undefined
  };

const readingReducer = (state=INIT_STATE,action) => {

    switch(action.type){
        case READING_SET_LIST:
             return {
                 ...state,
                 readings:action.payload
             }
        case READING_TOOGLE_LOADING:
            return{
                ...state,
                loading:action.payload,
            }
        default:
            return state
    }
}

export default readingReducer
