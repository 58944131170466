import Navbar from "../components/Navbar/Navbar";

const SecureLayout = ({ children }) => {
  return (
    <div>
      <Navbar/>
      <div className="parentContainer">{children}</div>
    </div>
  );
};

export default SecureLayout;
