import React from "react";
import { Button, Checkbox, Grid, GridColumn, Segment } from "semantic-ui-react";

const ItemViewer = ({
  index,
  primaryText,
  secondaryText,
  onEdit,
  onDelete,
  onItemClick,
  selectMultiple = false,
  isSelected = false,
  disableEdit = false,
  onDownloadClick,
}) => {
  return (
    <div className="container">
      <div
        className="container__group"
        style={{ display: "flex", flexGrow: "1" }}
      >
        <div
          className="circularContainer"
          style={{ backgroundColor: selectMultiple ? "whitesmoke" : "" }}
        >
          {selectMultiple ? (
            <Checkbox
              onChange={(e) => onItemClick(index)}
              checked={isSelected}
            />
          ) : (
            <p>{index + 1}</p>
          )}
        </div>
        <div className="itemTextContainer">
          <h3>{primaryText}</h3>
          <p>{secondaryText}</p>
        </div>
      </div>
      {!selectMultiple && (
        <div
          style={{
            // width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            onClick={
              disableEdit ? () => onDownloadClick(index) : () => onEdit(index)
            }
            color="teal"
          >
            {disableEdit ? "Download" : "Edit"}
          </Button>
          <Button onClick={() => onItemClick(index)} color="grey">
            View
          </Button>
          <Button onClick={() => onDelete(index)} color="red">
            Delete
          </Button>
        </div>
      )}
    </div>
  );
};

export default ItemViewer;
