import { deleteData, getData, postData } from "./apiClient"

export const getDynamicReceiptList = async({params=""})=>{
    const data = await getData({collectionName:"dynamic-receipts",params:params})
    return data
}

export const deleteDynamicReceipt=async({data})=>{
    console.log("del req called")
    const collectionName = "dynamic-receipt/"+data
    return await deleteData({collectionName:collectionName})
}
  