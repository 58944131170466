import { Button, Modal } from "semantic-ui-react";

const DetailsViewModal = ({showModal,landlord=undefined,onModalClose}) => {
    return (
        <Modal open={showModal} onClose={() => onModalClose()}>
        <Modal.Header>
            <h2>Landlord details</h2>
        </Modal.Header>
      <Modal.Content scrolling>
        {landlord && (
          <div>
            <p>{"Landlord name :  " + landlord.name}</p>
            <p>{"Is verfied :  " + landlord.is_verified}</p>
             <p>{"Created at :  " + landlord.createdAt.substring(0,10)}</p>
            <p>{"Updated at :  " + landlord.updatedAt.substring(0,10)}</p>
            <p>{"Updated by :  " + landlord.updated_by.name}</p> 
          </div>
        )}

      </Modal.Content>
      <Modal.Actions>
          <Button color="red" onClick={()=>onModalClose()}>Cancel</Button>
      </Modal.Actions>
    </Modal>
    )
}

export default DetailsViewModal
