import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../../redux/actions/index";
import { useLocation, useHistory } from "react-router-dom";
import { toQueryString } from "../../../utilities/helper";
import {
  findValueByProperty,
  generateDropdownArray,
  generateIncArrayAsDropdown,
} from "../../core/helpingFunctions";
import {
  Button,
  Confirm,
  Loader,
  Dimmer,
  Form,
  FormField,
} from "semantic-ui-react";
import ItemViewer from "../../components/ItemViewer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../services/api/apiClient";
import CreateDynamicReceiptModal from "./CreateDynamicReceiptModal";
import { DYNAMIC_RECEIPT_CREATE } from "../../../utilities/constants";
import { monthListBangla } from "../../core/listData";
import { formatDateTime } from "../../../utilities/dateUtils";

const DynamicReceiptFragment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  // const query.limit = 40;

  const [values, setValues] = useState({
    showCreateModal: false,
    showViewModal: false,
    showDeletePopUp: false,
    selectMultiple: false,
    itemsTobeDeleted: {},
    dynamicReceiptToBeShown: undefined,
    buildingList: [],
    renterList: [],
    renterListTemp: [],
    dynamicReceiptListTemp: [],
    selectedMonthValue: "",
    selectedYearValue: "",
    selectedBuildingValue: "",
    selectedRenterValue: "",
    idxValueOfDynamicReceipt: 0,
  });

  const {
    showCreateModal,
    showViewModal,
    showDeletePopUp,
    selectMultiple,
    itemsTobeDeleted,

    dynamicReceiptToBeShown,
    buildingList,
    renterList,
    renterListTemp,
    dynamicReceiptListTemp,
    selectedMonthValue,
    selectedYearValue,
    selectedBuildingValue,
    selectedRenterValue,
    idxValueOfDynamicReceipt,
  } = values;

  useEffect(() => {
    dispatch(actionCreators.getDynamicReceipts());
    dispatch(actionCreators.getBuildings());
    dispatch(actionCreators.getRenters());

    // const limit = new URLSearchParams(search).get("limit");
    // const skip = new URLSearchParams(search).get("skip");
    // const idx = new URLSearchParams(search).get("idx");
    // const renter = new URLSearchParams(search).get("renter");

    // setQuery({
    //   ...query,
    //   limit: limit ?? query.limit,
    //   skip: skip ?? query.skip,
    //   idx,
    //   renter,
    // });
  }, []);

  //async values
  const { loading: loadingBuilding, buildings } = useSelector(
    (state) => state.buildings
  );
  const { loading: loadingRenter, renters } = useSelector(
    (state) => state.renters
  );
  const { loading: loadingDynamicReceipt, dynamicReceipts } = useSelector(
    (state) => state.dynamicReceipts
  );

  //const values
  const [query, setQuery] = useState({ limit: 20, skip: 0 });
  const search = useLocation().search;
  const onQueryChange = (key, val) => {
    setQuery({
      ...query,
      [key]: val,
    });
  };

  useEffect(() => {
    console.log("Query:", query);
    if (Object.keys(query).length > 0) {
      const queryParams = toQueryString(query);
      history.push(`/dynamic-receipts${queryParams}`);
      console.log("searching ... ", queryParams);
      setTimeout(() => {
        console.log("search finished for ", queryParams);
        dispatch(actionCreators.getDynamicReceipts(query));
      }, 500);
    }
  }, [query]);

  useEffect(() => {
    if (pageNumber > -1) {
      setQuery({
        ...query,
        limit: query.limit,
        skip: pageNumber * query.limit,
      });
    }
  }, [pageNumber]);

  const monthList = generateDropdownArray({
    textArray: monthListBangla,
    startValue: 1,
  });

  const yearList = generateIncArrayAsDropdown({ start: 2020, size: 20 });

  useEffect(() => {
    if (dynamicReceipts) {
      setValues({
        ...values,
        dynamicReceiptListTemp: dynamicReceipts,
      });
    }
  }, [dynamicReceipts]);

  useEffect(() => {
    if (buildings) {
      let arrayId = [];
      let arrayName = [];
      buildings.forEach((building) => {
        arrayId.push(building._id);
        arrayName.push(building.name);
      });
      setValues({
        ...values,
        buildingList: generateDropdownArray({
          textArray: arrayName,
          useValueAsKey: false,
          key: arrayId,
          useLowerCaseValue: false,
        }),
      });
    }
  }, [buildings]);

  useEffect(() => {
    if (renters) {
      console.log("renter initiating");
      let arrayId = [];
      let arrayName = [];
      renters.forEach((renter) => {
        arrayId.push(renter._id);
        arrayName.push(renter.name);
      });
      let array = generateDropdownArray({
        textArray: arrayName,
        useValueAsKey: false,
        key: arrayId,
        useLowerCaseValue: false,
      });
      setValues({
        ...values,
        renterList: array,
        renterListTemp: array,
      });
    }
  }, [renters]);

  useEffect(() => {
    if (selectedBuildingValue !== "-1" && selectedBuildingValue !== "") {
      console.log("building selected:", selectedBuildingValue);
      let index = findValueByProperty({
        array: buildingList,
        searchingProperty: "text",
        searchFor: selectedBuildingValue,
        needIndex: true,
      });
      //console.log("index",index)
      let tempList = [];
      renters.forEach((renter) => {
        if (renter.building.name === buildingList[index].text) {
          tempList.push({
            key: renter._id,
            text: renter.name,
            value: renter.name,
          });
        }
      });
      console.log("temp ", tempList);
      setValues({
        ...values,
        renterListTemp: tempList,
      });
    } else {
      setValues({
        ...values,
        renterListTemp: renterList,
      });
    }
  }, [selectedBuildingValue]);

  //functions
  const onModalClose = () => {
    dispatch(actionCreators.delDynamicRecentMessageReceipt());
    setValues({
      ...values,
      showCreateModal: false,
      showViewModal: false,
      dynamicReceiptToBeShown: undefined,
    });
  };

  const onFilter = () => {
    // if(!(selectedBuildingValue==="" && selectedRenterValue==="" && selectedYearValue==="" && selectedMonthValue==="" && !idxValueOfDynamicReceipt>0)){
    //  }
    setQuery({
      ...query,
      building:
        selectedBuildingValue !== ""
          ? findValueByProperty({
              array: buildingList,
              searchingProperty: "value",
              searchFor: selectedBuildingValue,
              resultProperty: "key",
            })
          : undefined,
      renter:
        selectedRenterValue !== ""
          ? findValueByProperty({
              array: renterList,
              searchingProperty: "value",
              searchFor: selectedRenterValue,
              resultProperty: "key",
            })
          : undefined,
      month:
        selectedMonthValue !== "" ? parseInt(selectedMonthValue) : undefined,
      year: selectedYearValue != "" ? parseInt(selectedYearValue) : undefined,
      idx: idxValueOfDynamicReceipt > 0 ? idxValueOfDynamicReceipt : undefined,
      // limit: query.limit,
      // skip: pageNumber * query.limit,
    });
  };

  const onCreateNewDynamicReceipt = ({ mode, data }) => {
    if (mode === "create") {
      if (
        (data.building === "" || data.renter === "") &&
        data.month == "" &&
        data.year == ""
      ) {
        toast("Please slect apropritate data");
      } else {
        if (data.building === "") {
          data.building = undefined;
        }
        if (data.renter === "") {
          data.renter = undefined;
        }
        console.log("Dispatched", data);
        dispatch(actionCreators.createDynamicReceipt(data));
      }
    }
  };

  const onAddClick = (index) => {
    setValues({
      ...values,
      showCreateModal: true,
    });
  };

  const onItemClick = (index) => {
    if (selectMultiple) {
      let items = { ...itemsTobeDeleted };
      if (itemsTobeDeleted[index]) {
        delete items[index];
      } else {
        items = {
          ...itemsTobeDeleted,
          [index]: dynamicReceiptListTemp[index]._id,
        };
      }
      setValues({
        ...values,
        itemsTobeDeleted: items,
      });
    } else {
      setValues({
        ...values,
        dynamicReceiptToBeShown: dynamicReceiptListTemp[index],
        showViewModal: true,
      });
    }
    console.log("item clicked", index, dynamicReceiptListTemp[index]);
  };

  const onDeleteItem = () => {
    console.log("delete confirmed", itemsTobeDeleted);
    if (Object.entries(itemsTobeDeleted).length === 0) {
      toast("No item selected");
    } else {
      let deleteIds = [];
      for (const [key, value] of Object.entries(itemsTobeDeleted)) {
        deleteIds = [...deleteIds, value];
        // console.log(key,value)
      }
      dispatch(actionCreators.deleteDynamicReceiptAsList(deleteIds));
      console.log("delete ids", deleteIds);
      setValues({
        ...values,
        showDeletePopUp: false,
        itemsTobeDeleted: {},
        selectMultiple: false,
      });
    }
  };

  const onDownloadItem = (index) => {
    const url = baseUrl + dynamicReceiptListTemp[index].download_url;
    window.open(url, "_blank");
    console.log("download clicked", index);
  };

  const buildPrimaryText = (dynamicReceipt) => {
    const { building, month, year } = dynamicReceipt;
    return `${building?.name} - ${monthListBangla[month - 1]}, ${year}`;
  };

  const buildSecondaryText = (dynamicReceipt) => {
    const { createdAt } = dynamicReceipt;
    return `জেনারেট করার সময়ঃ ${formatDateTime(createdAt)}`;
  };

  return (
    <div className="parentContainer">
      <>
        <Form>
          <div className=" container">
            <Form.Dropdown
              label="Building"
              loading={loadingBuilding}
              clearable
              options={buildingList}
              placeholder="Choose building"
              selection
              onChange={(e, { value }) => {
                setValues({
                  ...values,
                  selectedBuildingValue: value,
                });
              }}
            />
            <Form.Dropdown
              label="Renter"
              loading={loadingRenter}
              clearable
              options={renterListTemp}
              placeholder="Choose renter"
              selection
              onChange={(e, { value }) => {
                setValues({
                  ...values,
                  selectedRenterValue: value,
                });
              }}
            />

            <Form.Dropdown
              label="Month"
              clearable
              options={monthList}
              placeholder="Choose month"
              selection
              onChange={(e, { value }) =>
                setValues({
                  ...values,
                  selectedMonthValue: value,
                })
              }
            />

            <Form.Dropdown
              label="Year"
              clearable
              options={yearList}
              placeholder="Choose year"
              selection
              onChange={(e, { value }) =>
                setValues({
                  ...values,
                  selectedYearValue: value,
                })
              }
            />

            <FormField>
              <label>Receipt Id</label>
              <input
                type="number"
                placeholder="Receipt id"
                onChange={(e) => {
                  setValues({
                    ...values,
                    idxValueOfDynamicReceipt: e.target.value,
                  });
                }}
              />
            </FormField>
            <Form.Button
              style={{ marginTop: "1.5rem" }}
              onClick={() => onFilter()}
            >
              Filter
            </Form.Button>
            <Form.Button
              style={{ marginTop: "10px", minWidth: "150px" }}
              color="orange"
              onClick={onAddClick}
            >
              Add new
            </Form.Button>
          </div>
        </Form>
      </>

      <div style={{ alignSelf: "flex-start" }}>
        {selectMultiple && (
          <Button
            onClick={() => {
              setValues({
                ...values,
                selectMultiple: false,
                itemsTobeDeleted: {},
              });
            }}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={() => {
            if (selectMultiple) {
              setValues({ ...values, showDeletePopUp: true });
            } else {
              setValues({ ...values, selectMultiple: !selectMultiple });
            }
          }}
          color={selectMultiple && "red"}
        >
          {selectMultiple ? "Delete all" : "Delete multiple"}
        </Button>
      </div>

      <div style={{ height: "50px", alignSelf: "flex-end" }}>
        {!selectMultiple && (
          <div>
            <Button
              disabled={pageNumber === 0}
              onClick={() => {
                setPageNumber(pageNumber !== 0 ? pageNumber - 1 : 0);
                setQuery({ ...query, skip: pageNumber * query.limit });
              }}
            >
              Previous
            </Button>
            <Button
              disabled={
                dynamicReceipts.length === 0 ||
                dynamicReceipts.length < query.limit
              }
              onClick={() => {
                dynamicReceipts.length !== 0 && setPageNumber(pageNumber + 1);
                setQuery({ ...query, skip: (pageNumber + 1) * query.limit });
              }}
            >
              Next
            </Button>
          </div>
        )}
      </div>

      {loadingDynamicReceipt ? (
        <span class="loader"></span>
      ) : dynamicReceiptListTemp.length !== 0 ? (
        dynamicReceiptListTemp.map((dynamicReceipt, index) => (
          <ItemViewer
            index={index + pageNumber * query.limit}
            selectMultiple={selectMultiple}
            isSelected={
              itemsTobeDeleted && itemsTobeDeleted[index] ? true : false
            }
            primaryText={buildPrimaryText(dynamicReceipt)}
            secondaryText={buildSecondaryText(dynamicReceipt)}
            onItemClick={onItemClick}
            onDownloadClick={onDownloadItem}
            disableEdit={true}
            onDelete={() => {
              setValues({
                ...values,
                itemsTobeDeleted: {
                  ...itemsTobeDeleted,
                  [index]: dynamicReceipt._id,
                },
                showDeletePopUp: true,
              });
            }}
          />
        ))
      ) : (
        <p>No receipts to show</p>
      )}

      {showCreateModal && (
        <CreateDynamicReceiptModal
          showModal={showCreateModal}
          onModalClose={onModalClose}
          buildingList={buildingList}
          renterList={renterList}
          renters={renters}
          monthList={monthList}
          onSubmit={onCreateNewDynamicReceipt}
          yearList={yearList}
        />
      )}

      <Dimmer active={false}>
        <Loader>Working on it</Loader>
      </Dimmer>

      <ToastContainer />
      <Confirm
        open={showDeletePopUp}
        confirmButton="Yes,delete "
        cancelButton="No,leave it"
        onConfirm={() => onDeleteItem()}
        onCancel={() =>
          setValues({
            ...values,
            itemsTobeDeleted: {},
            showDeletePopUp: false,
            selectMultiple: false,
          })
        }
      />
    </div>
  );
};

export default DynamicReceiptFragment;
