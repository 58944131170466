import {call, put, takeLatest, fork, all} from  "redux-saga/effects";
import { deleteDynamicReceipt, getDynamicReceiptList } from "../../services/api/DynamicReceipt";
import { postGenerateReceiptForBuildingOrRenter } from "../../services/api/Receipt";
import { DYNAMIC_RECEIPT_CREATE, DYNAMIC_RECEIPT_DELETE, DYNAMIC_RECEIPT_GET_LIST } from "../../utilities/constants";
import *as actionCreators from "../actions";

export function* getDynamicReceipts({payload}){
    yield put(actionCreators.toogleLoadingDynamicReceipt(true))
    try{
        const dynamicReceipts = yield call(getDynamicReceiptList,{params:payload})
        yield put(actionCreators.setDynamicReceipts(dynamicReceipts))
    }catch(error){
        console.log(error)
    }
    yield put(actionCreators.toogleLoadingDynamicReceipt(false))
}

export function* generateDynamicReceipt({payload}){
    yield put(actionCreators.toogleGenerateDynamicReceiptLoading(true))
    try{
        const res = yield call(postGenerateReceiptForBuildingOrRenter,{data:payload})
        if(res.error){
            console.log(res.error)
        }else{
            if(!res.data.download_url){
                yield put(actionCreators.setDynamicRecentMessageReceipt("No receipt found with this data"))
            }else{
                yield put(actionCreators.setDynamicRecentMessageReceipt("Ok"))
                console.log("Success",res)
                yield put(actionCreators.getDynamicReceipts())
            }
        }
    }catch(error){
        console.log(error)
    }
    yield put(actionCreators.toogleGenerateDynamicReceiptLoading(false))
}


export function* deleteDynamicReceiptAsList({payload}){
    try{
        console.log("Response collected in saga(delete)", payload)
        yield put(actionCreators.toogleLoadingDynamicReceipt(true))
        let res = yield all(payload.map((id)=>call(deleteDynamicReceipt,{data:id})))
        if(res.error){
            yield put(actionCreators.toogleLoadingDynamicReceipt(false))
            console.log(res.error)
        }else{
            console.log("Success", res)
            yield put(actionCreators.getDynamicReceipts())
        }
    }catch(error){
        console.log(error)
    }
}

export function* getDynamicReceiptsWatcher(){
    yield takeLatest(DYNAMIC_RECEIPT_GET_LIST,getDynamicReceipts)
}

export function* generateDynamicReceiptWatcher(){
    yield takeLatest(DYNAMIC_RECEIPT_CREATE,generateDynamicReceipt)
}

export function* deleteDynamicReceiptAsListWatcher(){
    yield takeLatest(DYNAMIC_RECEIPT_DELETE,deleteDynamicReceiptAsList)
}

export default function* rootSaga() {
    yield all([
        fork(getDynamicReceiptsWatcher),
        fork(generateDynamicReceiptWatcher),
        fork(deleteDynamicReceiptAsListWatcher),
    ])
}
