import { Button, Modal} from "semantic-ui-react";

const DetailsViewerModal = ({
  showModal,
  building = undefined,
  onModalClose,
}) => {

  return (
    <Modal open={showModal} onClose={() => onModalClose()}>
        <Modal.Header>
            <h2>Building details</h2>
        </Modal.Header>
      <Modal.Content scrolling>
        {building && (
          <div>
            <p>{"Building name :  " + building.name}</p>
            <p>{"Landlord :  " + building.landlord.name}</p>
            <p>{"Address :  " + building.address}</p>
            <p>{"Hotline :  " + building.hotline}</p>
            <p>{"Store-count:  " + building.store_count}</p>
            <p>{"Floor-count:  " + building.floor_count}</p>
            <p>{"Flat-count:  " + building.flat_count}</p>
            <p>{"Flat-info:  " + building.flat_info}</p>
            <p>{"Electricity unit price(Flat):  " + building.flat_electricity_unit_price}</p>
            <p>{"Electricity unit price(Store) :  " + building.store_electricity_unit_price}</p>
            <p>{"Gas unit price :  " + building.gas_unit_price}</p>
            <p>{"Created at :  " + building.createdAt.substring(0,10)}</p>
            <p>{"Updated at :  " + building.updatedAt.substring(0,10)}</p>
            <p>{"Updated by :  " + building.updated_by.name}</p>
          </div>
        )}

      </Modal.Content>
      <Modal.Actions>
          <Button color="red" onClick={()=>onModalClose()}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DetailsViewerModal;
