import {all} from  "redux-saga/effects"

import buildingSagas from './Building'
import landlordSagas from './Landlord'
import readingSagas from './Reading'
import renterSagas from './Renter'
import receiptSagas from './Receipt'
import dynamicReceiptSagas from './DynamicReceipt'

export default function* rootSaga() {
    yield all([
        buildingSagas(),
        landlordSagas(),
        readingSagas(),
        renterSagas(),
        receiptSagas(),
        dynamicReceiptSagas(),
    ])
}