import { deleteReadingAsList } from "../../redux/actions";
import { getData, postData, putData ,deleteData} from "./apiClient";


export const getRentersList = async (query) => {
    const data = await getData({collectionName:"renters",params:query})
    return data
  //   console.log(res.data)
};

export const postRenter = async ({data})=>{
  console.log("Post req called")
  return await postData({collectionName:"renter",data:data})
}

export const putRenter = async({data})=>{
  console.log("Put req called")
  const collectionName = "renter/"+data._id
  return await putData({collectionName:collectionName,data:data})
}


export const deleteRenter=async({data})=>{
  console.log("del req called")
  const collectionName = "renter/"+data
  return await deleteData({collectionName:collectionName})
}
