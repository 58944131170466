import { combineReducers } from "redux";
import buildingReducer from "./buildingReducer";
import landlordReducer from "./landlordReducer";
import readingReducer from "./readingReducer";
import receiptReducer from "./receiptReducer";
import renterReducer from "./renterReducer";
import dynamicReceiptReducer from "./dynamicReceiptReducer";
import appStatesReducer from "./appStatesReducer"

const reducers = combineReducers({
    buildings: buildingReducer,
    renters: renterReducer,
    landlords:landlordReducer,
    readings: readingReducer,
    receipts: receiptReducer,
    dynamicReceipts: dynamicReceiptReducer,
    appStates: appStatesReducer,
})

export default reducers;