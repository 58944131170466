import "./App.css";
import Login from "./app/components/LoginPage";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch, Link, useHistory } from "react-router-dom";
import SecureLayout from './app/hoc/SecureLayout';
import { Button, Confirm, Header } from "semantic-ui-react";
import BuildingsFragment from "./app/fragments/Building/BuildingsFragment";
import LandlordsFragment from "./app/fragments/Landlord/LandlordsFragment";
import ReadingsFragments from "./app/fragments/Reading/ReadingsFragments";
import RentersFragment from "./app/fragments/Renter/RentersFragment";
import ReceiptFragment from "./app/fragments/Receipt/ReceiptFragment";
import DynamicReceiptFragment from "./app/fragments/DynamicReceipt/DynamicReceiptFragment";
import * as actionCreators from "./redux/actions/index";
import { getCookie, getLocalStorage, removeCookie, removeLocalStorage } from "./utilities/session";
import ProfileFragment from "./app/fragments/Profile/ProfileFragment";



function App() {

  const dispatch = useDispatch()
  const {showlogOutConfirmation} = useSelector((state)=>state.appStates)

  const onLogout = () => {
    removeCookie("token")
    removeLocalStorage("user");
    dispatch(actionCreators.toogleLogOutConfirmation(false))
    // if (!getCookie("token") && !getLocalStorage("user")) {
    //   history.push("/");
    // }
    window.location.replace("/")
  };

  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route path="/" exact>
            <Login />
          </Route>

          <Route path="/renters">
            <SecureLayout>
              <RentersFragment />
            </SecureLayout>
          </Route>
          
          <Route path="/buildings">
            <SecureLayout>
              <BuildingsFragment />
            </SecureLayout>
          </Route>
          
          <Route path="/landlords">
            <SecureLayout>
              <LandlordsFragment />
            </SecureLayout>
          </Route>
          
          <Route path="/readings">
            <SecureLayout>
              <ReadingsFragments />
            </SecureLayout>
          </Route>
          
          <Route path="/receipts">
            <SecureLayout>
              <ReceiptFragment/>
            </SecureLayout>
          </Route>

          <Route path="/dynamic-receipts">
            <SecureLayout>
              <DynamicReceiptFragment/>
            </SecureLayout>
          </Route>
          <Route path="/profile">
            <SecureLayout>
              <ProfileFragment/>
            </SecureLayout>
          </Route>
        </Switch>
        <Confirm
        open={showlogOutConfirmation}
        confirmButton="Yes, logout"
        cancelButton="No, leave it"
        onConfirm={onLogout}
        onCancel={()=>dispatch(actionCreators.toogleLogOutConfirmation(false))}
        />
      </div>
    </BrowserRouter>
  );
}


export default App;
