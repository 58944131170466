import {
  DYNAMIC_RECEIPT_DEL_MESSAGE,
  DYNAMIC_RECEIPT_SET_LIST,
  DYNAMIC_RECEIPT_SET_MESSAGE,
  DYNAMIC_RECEIPT_TOOGLE_LOADING,
} from "../../utilities/constants";

const INIT_STATE = {
  loading: false,
  dynamicReceipts: [],
  message: undefined,
  generatePdfLoading: false,
};

const dynamicReceiptReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DYNAMIC_RECEIPT_SET_LIST:
      return {
        ...state,
        dynamicReceipts: action.payload,
      };
    case DYNAMIC_RECEIPT_TOOGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DYNAMIC_RECEIPT_TOOGLE_LOADING:
      return {
        ...state,
        generatePdfLoading: action.payload,
      };
    case DYNAMIC_RECEIPT_SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case DYNAMIC_RECEIPT_DEL_MESSAGE:
      return {
        ...state,
        message: undefined,
      };
    default:
      return state;
  }
};

export default dynamicReceiptReducer
