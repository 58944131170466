import { APP_STATE_TOOGLE_LOGOUT_CONFIRMATION } from "../../utilities/constants";

const INIT_STATE = {
  showlogOutConfirmation: false,
};

const appStatesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case APP_STATE_TOOGLE_LOGOUT_CONFIRMATION:
      return {
        ...state,
        showlogOutConfirmation: action.payload,
      };
    default:
      return state;
  }
};

export default appStatesReducer;
