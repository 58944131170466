const moment = require("moment");

exports.formatDateTime = (dateTime) => {
  return moment(dateTime).format("MM-DD-YYYY hh:mm A");
};

exports.formatDateTime2 = (dateTime) => {
  return moment(dateTime).format("hh:mm A ddd, MM MMMM, YYYY");
};

exports.formatDate = (dateTime) => {
  return moment(dateTime).format("ddd, MM MMMM, YYYY");
};

exports.formatTime = (dateTime) => {
  return moment(dateTime).format("hh:mm A");
};

exports.calcDays = (dateTime) => {
  var given = moment(dateTime);
  var current = moment().startOf("day");

  return Math.floor(moment.duration(given.diff(current)).asDays());
};

exports.daysLeftText = (days) => {
  return `${days}${days < 2 ? " day left" : " days left"}`;
};
