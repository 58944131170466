import { deleteData, getData, postData, putData } from "./apiClient";

export const getReadingsList = async (query) => {
  const data = await getData({ collectionName: "readings", params: query });
  return data;
  //   console.log(res.data)
};
export const postReading = async ({ data }) => {
  console.log("Post req called");
  return await postData({ collectionName: "reading", data: data });
};

export const putReading = async ({ data }) => {
  console.log("Put req called");
  const collectionName = "reading/" + data._id;
  return await putData({ collectionName: collectionName, data: data });
};

export const deleteReading = async ({ data }) => {
  console.log("del req called");
  const collectionName = "reading/" + data;
  return await deleteData({ collectionName: collectionName });
};
