// APP STATES
export const APP_STATE_TOOGLE_LOGOUT_CONFIRMATION = "APP_STATE_TOOGLE_LOGOUT_POPUP"


//BUIDINGS
export const BUILDING_GET_LIST = "building_get_list"
export const BUILDING_SET_LIST = "building_set_list"
export const BUILDING_UPDATE = "building_update"
export const BUILDING_DELETE = "building_delete"
export const BUILDING_CREATE = "building_create"
export const BUILDING_TOOGLE_LOADING = "building_toogle_loading"

//Renters
export const RENTER_GET_LIST = "renter_get_list"
export const RENTER_SET_LIST = "renter_set_list"
export const RENTER_UPDATE = "renter_update"
export const RENTER_DELETE = "renter_delete"
export const RENTER_CREATE = "renter_create"
export const RENTERD_TOOGLE_LOADING = "renter_toogle_loading"

//lANDLORDS
export const LANDLORD_GET_LIST = "landlord_get_list"
export const LANDLORD_SET_LIST = "landlord_set_list"
export const LANDLORD_UPDATE = "landlord_update"
export const LANDLORD_DELETE = "landlord_delete"
export const LANDLORD_CREATE = "landlord_create"
export const LANDLORD_TOOGLE_LOADING = "landlord_toogle_loading"

//Readings
export const READING_GET_LIST = "reading_get_list"
export const READING_SET_LIST = "reading_set_list"
export const READING_UPDATE = "reading_update"
export const READING_DELETE = "reading_delete"
export const READING_CREATE = "reading_create"
export const READING_TOOGLE_LOADING = "reading_toogle_loading"


//Receipts
export const RECEIPT_GET_LIST = "receipt_get_list"
export const RECEIPT_SET_LIST = "receipt_set_list"
export const RECEIPT_SET_PDF_URL_BUILDING="receipt_set_pdf_url_building"
export const RECEIPT_DEL_PDF_URL_BUILDING="receipt_del_pdf_url_building"
export const RECEIPT_UPDATE = "receipt_update"
export const RECEIPT_DELETE = "receipt_delete"
export const RECEIPT_CREATE = "receipt_create"
export const RECEIPT_TOOGLE_LOADING = "receipt_toogle_loading"
export const RECEIPT_GENERATE_RENTER = "receipt_generate_renter"
export const RECEIPT_POST_GENERATE_BUILDING ="receipt_generate_building"
export const RECEIPT_GENERATE_TOOGLE_LOADING = "receipt_generate_toogle_loading"
export const RECEIPT_SET_MESSAGE = "receipt_set_message"
export const RECEIPT_DEL_MESSAGE = "receipt_del_message"


//Dynamic Receipts
export const DYNAMIC_RECEIPT_GET_LIST = "dynamic_receipt_get_list"
export const DYNAMIC_RECEIPT_SET_LIST = "dynamic_receipt_set_list"
export const DYNAMIC_RECEIPT_DELETE = "dynamic_receipt_delete"
export const DYNAMIC_RECEIPT_CREATE = "dynamic_receipt_create"
export const DYNAMIC_RECEIPT_TOOGLE_LOADING = "dynamic_receipt_toogle_loading"
export const DYNAMIC_RECEIPT_GENERATE_TOOGLE_LOADING = "dynamic_receipt_generate_toogle_loading"
export const DYNAMIC_RECEIPT_SET_MESSAGE = "dynamic_receipt_set_message"
export const DYNAMIC_RECEIPT_DEL_MESSAGE = "dynamic_receipt_del_message"