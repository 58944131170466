import {
  postLandlord,
  getLandlordsList,
  putLandlord,
  deleteLandlord,
} from "../../services/api/Landlord";
import *as actionCreators from "../actions";
import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import { LANDLORD_CREATE, LANDLORD_DELETE, LANDLORD_GET_LIST, LANDLORD_UPDATE } from "../../utilities/constants";

export function* getLandlords({payload}) {
  yield put(actionCreators.toogleLoadingLandlord(true));
  try {
    const res = yield call(getLandlordsList,payload);
    yield put(actionCreators.toogleLoadingLandlord(false));
    yield put(actionCreators.setLandlords(res));
  } catch (error) {
    yield put(actionCreators.toogleLoadingLandlord(false));
    console.log(error);
  }
  yield put(actionCreators.toogleLoadingLandlord(false));
}

export function* createLandlord({ payload }) {
  try {
    console.log("Got from ac", payload);
    yield put(actionCreators.toogleLoadingLandlord(true));
    const res = yield call(postLandlord, { data: payload });
    if (res.error) {
      yield put(actionCreators.toogleLoadingLandlord(false));
      console.log(res.error);
    } else {
      console.log("Success", res);
      yield put(actionCreators.getLandlords())
    }
  } catch (error) {
    console.log(error);
  }
}


export function* updateLandlord({ payload }) {
  try {
    console.log("Got from ac", payload);
    yield put(actionCreators.toogleLoadingLandlord(true));
    const res = yield call(putLandlord, { data: payload });
    if (res.error) {
      yield put(actionCreators.toogleLoadingLandlord(false));
      console.log(res.error);
    } else {
      console.log("Success", res);
      yield put(actionCreators.getLandlords())
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteLandlordAsList({payload}){
  try{
      console.log("Response collected in saga(delete)", payload)
      yield put(actionCreators.toogleLoadingLandlord(true))
      let res = yield all(payload.map((id)=>call(deleteLandlord,{data:id})))
      if(res.error){
          yield put(actionCreators.toogleLoadingLandlord(false))
          console.log(res.error)
      }else{
          console.log("Success", res)
          yield put(actionCreators.getLandlords())
      }
  }catch(error){
      console.log(error)
  }
}



export function* getLandlordsWatcher() {
  yield takeLatest(LANDLORD_GET_LIST, getLandlords);
}
export function* postLandlordWatcher() {
  yield takeLatest(LANDLORD_CREATE, createLandlord);
}
export function* putLandlordWatcher(){
    yield takeLatest(LANDLORD_UPDATE,updateLandlord)
}
export function* deleteLandlordAsListWatcher(){
  yield takeLatest(LANDLORD_DELETE,deleteLandlordAsList)
}

export default function* rootSaga() {
  yield all([
    fork(getLandlordsWatcher), 
    fork(postLandlordWatcher), 
    fork(putLandlordWatcher),
    fork(deleteLandlordAsListWatcher),
  ]);
}
