import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, Card, Confirm } from "semantic-ui-react";
import {
  generateDropdownArray,
  findValueByProperty,
  generateIncArrayAsDropdown,
} from "../../core/helpingFunctions";
import { flatListBangla, monthListBangla } from "../../core/listData";

const CreateUpdateReading = ({
  data,
  showModal,
  onModalClose,
  onSubmit,
  buildings = [],
}) => {
  const [values, setValues] = useState({
    showModal: showModal,
    selectedReadingValue: "-1",
    selectedMeterValue: "-1",
    selectedMonth: "-1",
    selectedBuildingValue: "-1",
    buildingInitiated: false,
    buildingList: [],
    flatList: [],
    floorList: [],
    readingData: {
      building: "",
      reading_type: "",
      meter_type: "",
      month: 0,
      year: 0,
      entry_date: "",
      entries: [],
    },
  });
  const [extras, setExtras] = useState({
    showAlert: false,
  });

  const { showAlert } = extras;

  const readingTypes = generateDropdownArray({
    textArray: ["Electricity", "Gas"],
  });

  const meterTypes = generateDropdownArray({ textArray: ["Main", "Sub"] });

  const months = generateDropdownArray({
    textArray: monthListBangla,
    startValue: 1,
  });

  const {
    readingData,
    selectedReadingValue,
    selectedMeterValue,
    selectedMonth,
    selectedBuildingValue,
    flatList,
    buildingInitiated,
    buildingList,
    floorList,
  } = values;

  //building List generation once
  const createBuildingData = () => {
    let buildingNames = [];
    buildings.forEach((building) => {
      buildingNames.push(building.name);
    });
    setValues({
      ...values,
      buildingList: generateDropdownArray({
        textArray: buildingNames,
        useLowerCaseValue: false,
      }),
      buildingInitiated: true,
    });
  };

  !buildingInitiated && createBuildingData();

  //loading data on case: edit
  useEffect(() => {
    if (data) {
      let monthValue = data.month > 0 ? months[data.month - 1].value : "-1";
      let index = findValueByProperty({
        array: buildings,
        searchingProperty: "_id",
        searchFor: data.building._id,
        needIndex: true,
      });

      setValues({
        ...values,
        selectedMeterValue: data.meter_type,
        selectedMonth: monthValue,
        selectedReadingValue: data.reading_type,
        selectedBuildingValue: data.building.name,
        readingData: data,
        flatList: generateDropdownArray({
          textArray: buildings[index].flat_info,
        }),
        floorList: generateDropdownArray({
          textArray: flatListBangla.slice(0, buildings[index].floor_count),
          startValue: 0,
          useLowerCaseValue: false,
          useValueAsKey: true,
        }),
      });
    }
  }, [data]);

  // for check purpose
  useEffect(() => {
    console.log("All data", values);
  }, [values]);

  const setReadingData = ({ property, data }) => {
    setValues({
      ...values,
      readingData: {
        ...readingData,
        [property]: data,
      },
    });
  };

  useEffect(() => {
    if (selectedBuildingValue !== "-1") {
      let index = findValueByProperty({
        array: buildings,
        searchingProperty: "name",
        searchFor: selectedBuildingValue,
        needIndex: true,
      });

      // console.log("index", index);
      index !== "-1" &&
        setValues({
          ...values,
          readingData: {
            ...readingData,
            building: buildings[index]._id,
          },
          flatList: generateDropdownArray({
            textArray: buildings[index].flat_info,
            useLowerCaseValue: false,
          }),
          floorList: generateDropdownArray({
            textArray: flatListBangla.slice(0, buildings[index].floor_count),
            startValue: 0,
            useLowerCaseValue: false,
            useValueAsKey: true,
          }),
        });
      if (readingData?.entries?.length > 0) {
        setExtras({ ...extras, showAlert: true });
      }
    }
  }, [selectedBuildingValue]);

  useEffect(() => {
    if (selectedMeterValue !== "-1") {
      setReadingData({
        property: "meter_type",
        data: selectedMeterValue.toLowerCase(),
      });
    }
  }, [selectedMeterValue]);

  useEffect(() => {
    if (selectedReadingValue !== "-1") {
      setReadingData({
        property: "reading_type",
        data: selectedReadingValue.toLowerCase(),
      });
    }
  }, [selectedReadingValue]);

  useEffect(() => {
    if (selectedMonth !== "-1") {
      setReadingData({
        property: "month",
        data: months[parseInt(selectedMonth) - 1].key,
      });
    }
  }, [selectedMonth]);

  //for test purpose
  useEffect(() => {
    console.log("reading data", readingData);
  }, [readingData]);

  //Functions
  const updateEntry = ({ index, property, data }) => {
    let entries = [...readingData.entries];
    entries[index] = {
      ...entries[index],
      [property]: data,
    };
    setValues({
      ...values,
      readingData: {
        ...readingData,
        entries: entries,
      },
    });
  };

  const onConfirmAlert = () => {
    setExtras({ ...extras, showAlert: false });
    setValues({
      ...values,
      readingData: {
        ...readingData,
        entries: [],
      },
    });
  };
  const onCancelAlert = () => {
    setExtras({ ...extras, showAlert: false });
  };

  const deleteEntry = (index) => {
    let entries = [...readingData.entries];
    entries.splice(index, 1);
    // console.log("After delete", entries);
    setValues({
      ...values,
      readingData: {
        ...readingData,
        entries: entries,
      },
    });
  };

  return (
    <Modal open={showModal} onClose={() => onModalClose()}>
      <Modal.Header>
        <h3>{data ? "Update Reading data" : "Add new Reading"}</h3>
      </Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <Form.Dropdown
            label="Building"
            placeholder="Choose building"
            selection
            required
            options={buildingList}
            value={selectedBuildingValue}
            onChange={(e) => {
              setValues({
                ...values,
                selectedBuildingValue: e.target.innerText,
              });
            }}
          />
          <Form.Group widths="two">
            <Form.Dropdown
              label="Reading type"
              placeholder="Choose reading type"
              selection
              required
              options={readingTypes}
              onChange={(e) =>
                setValues({
                  ...values,
                  selectedReadingValue: e.target.innerText.toLowerCase(),
                })
              }
              value={selectedReadingValue}
            />
            <Form.Dropdown
              label="Meter type"
              placeholder="Choose meter type"
              selection
              required
              options={meterTypes}
              onChange={(e) =>
                setValues({
                  ...values,
                  selectedMeterValue: e.target.innerText.toLowerCase(),
                })
              }
              value={selectedMeterValue}
            />
          </Form.Group>

          <Form.Group widths="three">
            <Form.Dropdown
              label="Month"
              placeholder="Choose month"
              selection
              required
              options={months}
              onChange={(e) => {
                console.log(e);
                let value = findValueByProperty({
                  array: months,
                  searchingProperty: "text",
                  searchFor: e.target.innerText,
                  resultProperty: "value",
                });
                setValues({ ...values, selectedMonth: value });
              }}
              defaultValue={data && months[data.month - 1].text}
              value={selectedMonth}
            />
            <Form.Field>
              <label>Year</label>
              <input
                placeholder="Year..."
                required
                defaultValue={data && data.year}
                type="number"
                onChange={(e) =>
                  setReadingData({ property: "year", data: e.target.value })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Entry date</label>
              <input
                defaultValue={
                  data && data.entry_date && data.entry_date.substring(0, 10)
                }
                type="date"
                required
                onChange={(e) =>
                  setReadingData({
                    property: "entry_date",
                    data: e.target.value,
                  })
                }
              />
            </Form.Field>
          </Form.Group>
          <br />
          <Button
            color="green"
            disabled={
              data ||
              selectedBuildingValue === "-1" ||
              buildings[
                findValueByProperty({
                  array: buildings,
                  searchingProperty: "name",
                  searchFor: selectedBuildingValue,
                  needIndex: true,
                }) ?? 0
              ].snippets?.length === 0
            }
            onClick={() => {
              let i = findValueByProperty({
                array: buildings,
                searchingProperty: "name",
                searchFor: selectedBuildingValue,
                needIndex: true,
              });
              console.log(i, buildings[i].snippets);
              setValues({
                ...values,
                readingData: {
                  ...readingData,
                  entries: buildings[i].snippets,
                },
              });
            }}
          >
            Populate template
          </Button>
          {readingData.entries.length === 0 ? (
            <p>No entries found</p>
          ) : (
            readingData.entries.map((entry, index) => (
              <Card fluid>
                <Card.Content>
                  <Form.Group widths="equals">
                    <Form.Dropdown
                      label="Floor"
                      required
                      selection
                      placeholder={
                        floorList.length === 0
                          ? "Select building First"
                          : "Choose floor"
                      }
                      options={floorList}
                      value={
                        entry.floor < floorList.length
                          ? flatListBangla[entry.floor]
                          : ""
                      }
                      onChange={(e, { value }) =>
                        updateEntry({
                          index: index,
                          property: "floor",
                          data: flatListBangla.indexOf(value),
                        })
                      }
                    ></Form.Dropdown>
                    <Form.Dropdown
                      label="Flat"
                      required
                      placeholder={
                        flatList.length === 0
                          ? "Select building First"
                          : "Choose flat"
                      }
                      selection
                      options={flatList}
                      value={
                        entry.flat < flatList.length
                          ? flatList[entry.flat]?.value
                          : ""
                      }
                      onChange={(e) => {
                        updateEntry({
                          index: index,
                          property: "flat",
                          data: findValueByProperty({
                            array: flatList,
                            searchingProperty: "value",
                            searchFor: e.target.innerText,
                            needIndex: true,
                          }),
                        });
                      }}
                    ></Form.Dropdown>
                    <Form.Field>
                      <label>Used unit</label>
                      <input
                        placeholder="Used unit"
                        // value={
                        //   data &&
                        //   index < data.entries.length &&
                        //   readingData.entries[index].used_unit
                        // }
                        type="number"
                        value={entry.used_unit}
                        onChange={(e) =>
                          updateEntry({
                            index: index,
                            property: "used_unit",
                            data: e.target.value,
                          })
                        }
                      />
                    </Form.Field>

                    <Form.Checkbox
                      label="Is store?"
                      control="input"
                      checked={readingData.entries[index].is_store}
                      type="checkbox"
                      onChange={(e) =>
                        updateEntry({
                          index: index,
                          property: "is_store",
                          data: e.target.checked,
                        })
                      }
                    ></Form.Checkbox>
                    <Button color="red" onClick={() => deleteEntry(index)}>
                      Delete entry
                    </Button>
                  </Form.Group>
                </Card.Content>
              </Card>
            ))
          )}
          <Button
            color="orange"
            onClick={() =>
              setValues({
                ...values,
                readingData: {
                  ...readingData,
                  entries: readingData.entries.concat({
                    floor: 0,
                    flat: 0,
                    used_unit: 0,
                    is_store: false,
                  }),
                },
              })
            }
          >
            Add entry
          </Button>
          {/* <Form.Button type="submit">Submit</Form.Button> */}
        </Form>
        <Confirm
          open={showAlert}
          centered
          content="Do you want to reset the reading entries?"
          onCancel={onCancelAlert}
          onConfirm={onConfirmAlert}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button
          color="brown"
          onClick={() =>
            onSubmit({ mode: data ? "edit" : "create", data: readingData })
          }
        >
          {data ? "Update Reading" : "Create Reading"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CreateUpdateReading;
