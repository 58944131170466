import { deleteData, getData, postData, putData } from "./apiClient"


export const getReceiptList = async ({ params = "" }) => {
    const data = await getData({ collectionName: "receipts", params: params })
    return data
}

export const postReceipt = async ({ data }) => {
    console.log("Post req called")
    return await postData({ collectionName: "receipt", data: data })
}

export const putReceipt = async ({ data: data }) => {
    console.log("Put req called")
    const collectionName = "receipt/" + data._id
    return await putData({ collectionName: collectionName, data: data })
}

export const deleteReceipt = async ({ data }) => {
    console.log("del req called")
    const collectionName = "receipt/" + data
    return await deleteData({ collectionName: collectionName })
}

export const getGenerateReceiptForRenter = async ({ data }) => {
    console.log("get gen called ")
    const collectionName = "generate/receipt/" + data.idx
    return await getData({ collectionName: collectionName })
}

export const postGenerateReceiptForBuildingOrRenter = async ({ data }) => {
    console.log("get gen called for building receipt")
    const collectionName = "generate/receipts"
    return await postData({ collectionName: collectionName, data: data })
}