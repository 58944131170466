export const flatListBangla = [
  "১ম",
  "২য়",
  "৩য়",
  "৪র্থ",
  "৫ম",
  "৬ষ্ঠ",
  "৭ম",
  " ৮ম",
  "৯ম",
  "১০ম",
  "১১তম",
  "১২তম",
  "১৩তম",
  "১৪তম",
  "১৫তম",
];

export const monthListBangla = [
  "জানুয়ারি",
  "ফেব্রুয়ারি",
  "মার্চ",
  "এপ্রিল",
  "মে",
  "জুন",
  "জুলাই",
  "আগস্ট",
  "সেপ্টেম্বর",
  "অক্টোবর",
  "নভেম্বর",
  "ডিসেম্বর",
];

export const monthListEnglish = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
