import { Button, Modal, Segment } from "semantic-ui-react";
import { flatListBangla } from "../../core/listData";

const DetailsViewerModal = ({
  showModal,
  reading = undefined,
  onModalClose,
  buildings = [],
}) => {
  var flatInfo = [];
  buildings.forEach((building) => {
    if (building._id === reading.building._id) {
      flatInfo = building.flat_info;
      return;
    }
  });
  console.log(flatInfo);
  return (
    <Modal open={showModal} onClose={() => onModalClose()}>
      <Modal.Header>
        <h2>Reading deatils</h2>
      </Modal.Header>
      <Modal.Content scrolling>
        {reading && (
          <div>
            <p>{"Building name :  " + reading.building.name}</p>
            <p>{"Reading type :  " + reading.reading_type}</p>
            <p>{"Meter type :  " + reading.meter_type}</p>
            <p>{"Month :  " + reading.month}</p>
            <p>{"Year:  " + reading.year}</p>
            <p>{"Entry date :  " + reading.entry_date.substring(0, 10)}</p>
            <p>{"Created at :  " + reading.createdAt.substring(0, 10)}</p>
            <p>{"Updated at :  " + reading.updatedAt.substring(0, 10)}</p>
            <p>Entries :</p>
            {reading.entries.length > 0 ? (
              reading.entries.map((entry) => (
                <Segment>
                  <p>{"floor : " + flatListBangla[entry.floor]}</p>
                  <p>{"flat : " + flatInfo[entry.flat]}</p>
                  <p>{"Store : " + entry.is_store}</p>
                </Segment>
              ))
            ) : (
              <p>No entries found</p>
            )}
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => onModalClose()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DetailsViewerModal;
