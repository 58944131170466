import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dimmer, Loader, Confirm } from "semantic-ui-react";
import ActivityPanel from "../../components/ActivityPanel";
import ItemViewer from "../../components/ItemViewer";
import * as actionCreators from "../../../redux/actions/index";
import CreateUpdateLandlordModal from "./CreateUpdateLandlordModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DetailsViewModal from "./DetailsViewModal";
import { useHistory } from "react-router-dom";
import { toQueryString } from "../../../utilities/helper";

const LandlordsFragment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [query, setQuery] = useState({ limit: 20, skip: 0 });
  const [pageNumber, setPageNumber] = useState(0);

  const { loading, landlords, message } = useSelector(
    (state) => state.landlords
  );

  const [values, setValues] = useState({
    landlordsListTemp: [],
    showEditModal: false,
    showViewModal: false,
    showDeletePopUp: false,
    selectMultiple: false,
    landlordToBeUpdated: undefined,
    landlordToBeShown: undefined,
    itemsTobeDeleted: {},
  });

  const {
    landlordsListTemp,
    showEditModal,
    showDeletePopUp,
    selectMultiple,
    showViewModal,
    landlordToBeShown,
    itemsTobeDeleted,
    landlordToBeUpdated,
  } = values;

  const setData = () => {
    setValues({
      ...values,
      landlordsListTemp: landlords,
    });
  };

  useEffect(() => {
    const queryParams = toQueryString(query);
    history.push(`/landlords${queryParams}`);
    dispatch(actionCreators.getLandlords());
  }, [query]);

  useEffect(() => {
    // console.log(landlords)
    landlords && setData();
    console.log("landlords effect");
  }, [landlords]);

  // console.log("isLoading",loading)

  const createNewLanlord = ({ data }) => {
    console.log("Dispatching createLandlord");
    dispatch(actionCreators.createLandlord(data));
  };

  const updateLandlordData = async ({ data }) => {
    console.log("Dispatching updateLandlord");
    dispatch(actionCreators.updateLandlord(data));
  };

  const onModalClose = () => {
    setValues({
      ...values,
      showEditModal: false,
      landlordToBeUpdated: undefined,
      landlordToBeShown: undefined,
      showViewModal: false,
    });
  };

  const onCreateOrEdit = ({ mode, data }) => {
    if (data.name === "") {
      toast("Please fill the required fields");
    } else {
      onModalClose();
      switch (mode) {
        case "create":
          createNewLanlord({ data: data });
          return;
        case "edit":
          updateLandlordData({ data: data });
          return;
        default:
          console.log(mode);
          console.log(data);
          return;
      }
    }
  };

  const onAddClick = () => {
    setValues({
      ...values,
      showEditModal: true,
    });
  };

  const onItemClick = (index) => {
    if (selectMultiple) {
      let items = { ...itemsTobeDeleted };
      if (itemsTobeDeleted[index]) {
        delete items[index];
      } else {
        items = { ...itemsTobeDeleted, [index]: landlordsListTemp[index]._id };
      }
      setValues({
        ...values,
        itemsTobeDeleted: items,
      });
    } else {
      setValues({
        ...values,
        landlordToBeShown: landlordsListTemp[index],
        showViewModal: true,
      });
    }
    console.log("item click", index);
  };

  const onEditItem = (index) => {
    setValues({
      ...values,
      landlordToBeUpdated: landlordsListTemp[index],
      showEditModal: true,
    });
    console.log("edit click", index);
  };

  const onDeleteItem = (index) => {
    console.log("delete confirmed", itemsTobeDeleted);
    if (Object.entries(itemsTobeDeleted).length === 0) {
      toast("No item selected");
    } else {
      let deleteIds = [];
      for (const [key, value] of Object.entries(itemsTobeDeleted)) {
        deleteIds = [...deleteIds, value];
        // console.log(key,value)
      }
      dispatch(actionCreators.deleteLandlordAsList(deleteIds));
      console.log("delete ids", deleteIds);
      setValues({
        ...values,
        showDeletePopUp: false,
        itemsTobeDeleted: {},
        selectMultiple: false,
      });
    }
  };

  const onSearch = ({ key, value }) => {
    setQuery({
      ...query,
      [key]: value,
    });
  };

  // const onSearch = (text) => {
  //   if (text === "" || text == null) {
  //     setData();
  //   } else {
  //     text = text.toLowerCase();
  //     setValues({
  //       ...values,
  //       landlordsListTemp: landlords.filter((landlord) => {
  //         var res1 = landlord.name.toLowerCase().match(text);
  //         // console.log(res1)
  //         return res1 == null ? false : true;
  //       }),
  //     });
  //   }
  //   // console.log(search)
  // };
  return (
    <div className="parentContainer">
      <ActivityPanel
        title="Landlords"
        onAddClick={onAddClick}
        onChange={(value) => onSearch({ key: "name", value: value })}
        placeholder="Search by landlord name..."
      />
      <div style={{ alignSelf: "flex-start" }}>
        {selectMultiple && (
          <Button
            onClick={() => {
              setValues({
                ...values,
                selectMultiple: false,
                itemsTobeDeleted: {},
              });
            }}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={() => {
            if (selectMultiple) {
              setValues({ ...values, showDeletePopUp: true });
            } else {
              setValues({ ...values, selectMultiple: !selectMultiple });
            }
          }}
          color={selectMultiple && "red"}
        >
          {selectMultiple ? "Delete all" : "Delete multiple"}
        </Button>
      </div>
      <div style={{ height: "50px", alignSelf: "flex-end" }}>
        {!selectMultiple && (
          <div>
            <Button
              disabled={pageNumber === 0}
              onClick={() => {
                setPageNumber(pageNumber !== 0 ? pageNumber - 1 : 0);
                setQuery({ ...query, skip: pageNumber * query.limit });
              }}
            >
              Previous
            </Button>
            <Button
              disabled={
                landlords.length === 0 || landlords.length < query.limit
              }
              onClick={() => {
                landlords.length !== 0 && setPageNumber(pageNumber + 1);
                setQuery({ ...query, skip: (pageNumber + 1) * query.limit });
              }}
            >
              Next
            </Button>
          </div>
        )}
      </div>
      {loading ? (
        <span class="loader"></span>
      ) : landlordsListTemp.length === 0 ? (
        <p>No landlord to show</p>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {landlordsListTemp.map((landlord, index) => (
            <ItemViewer
              index={index + pageNumber * query.limit}
              primaryText={landlord.name}
              selectMultiple={selectMultiple}
              isSelected={itemsTobeDeleted[index] ? true : false}
              secondaryText={landlord.is_verified ? "ভেরিফাইড" : "ভেরিফাইড না"}
              onItemClick={onItemClick}
              onEdit={onEditItem}
              onDelete={() => {
                setValues({
                  ...values,
                  itemsTobeDeleted: {
                    ...itemsTobeDeleted,
                    [index]: landlord._id,
                  },
                  showDeletePopUp: true,
                });
              }}
            />
          ))}
        </div>
      )}
      {showEditModal && (
        <CreateUpdateLandlordModal
          showModal={showEditModal}
          onModalClose={onModalClose}
          data={landlordToBeUpdated}
          onSubmit={onCreateOrEdit}
        />
      )}
      {showViewModal && (
        <DetailsViewModal
          showModal={showViewModal}
          onModalClose={onModalClose}
          landlord={landlordToBeShown}
        />
      )}

      <Dimmer active={false}>
        <Loader>Working on it</Loader>
      </Dimmer>
      <ToastContainer />
      <Confirm
        open={showDeletePopUp}
        confirmButton="Yes,delete "
        cancelButton="No,leave it"
        onConfirm={() => onDeleteItem()}
        onCancel={() =>
          setValues({
            ...values,
            itemsTobeDeleted: {},
            showDeletePopUp: false,
            selectMultiple: false,
          })
        }
      />
    </div>
  );
};

export default LandlordsFragment;
